<template>
  <b-container v-if="list.length" class="sticky-top">
    <b-row>
      <b-col :class="{ 'pt-3': list.length > 0 }">
        <b-alert
          v-for="(item, index) in list"
          :key="index"
          show
          :variant="item.variant"
          class="d-flex justify-content-between"
          @click.native="toggleCollapsed({ index: index, collapsed: !item.collapsed })"
          :class="{
            'cursor-pointer': canBeCollapsed(item),
          }"
        >
          <div class="d-flex">
            <template v-if="item.type === 'soap'">
              <span class="my-auto mr-2">
                <b-icon-server />
              </span>
              <span class="mx-2 white-space-pre-wrap">
                <span v-if="item.text" :inner-html.prop="formattedText(item.text) | capitalize"></span>
                <span v-else :inner-html.prop="$t(`soap.${item.code}`) | capitalize"></span>
              </span>
            </template>
            <template v-else-if="item.type === 'sessionExpired'">
              <span class="my-auto mr-2">
                <b-icon-exclamation-circle />
              </span>
              <span class="mx-2 white-space-pre-wrap" id="session-expires-notification">
                {{
                  $t("login.sessionExpires", {
                    sessionTimeout: Math.round(item.sessionTimeout / (60 * 1000)) + " min",
                  })
                }}
                <b-button variant="button" class="text-white btn-primary ml-2" @click.prevent="logOut()" id="session-relogin">
                  {{ $t("login.login") | capitalize }}
                </b-button>
              </span>
            </template>
            <template v-else>
              <span class="my-auto mr-2">
                <b-icon-exclamation-circle v-if="item.type === 'general'" />
                <b-icon-patch-exclamation v-if="item.type === 'attention'" />
                <b-icon-check-circle v-if="item.type === 'greeting'" />
              </span>
              <div class="align-items-center ml-2">
                <span v-if="item.message !== undefined && item.message.length">
                  {{ item.message }}
                </span>

                <div v-if="item.htmlMessage !== undefined && item.htmlMessage.length" class="white-space-pre-wrap d-block">
                  <b-collapse v-if="canBeCollapsed(item)" v-model="item.collapsed">
                    <span v-html="item.htmlMessage" class="mt-2 d-block"></span>
                  </b-collapse>
                  <span v-else v-html="item.htmlMessage" class="d-block" :class="{ 'mt-2': canBeCollapsed(item) }"></span>
                </div>
                <b-icon
                  v-if="canBeCollapsed(item)"
                  :icon="item.collapsed ? 'arrow-up-circle' : 'arrow-down-circle'"
                  class="centered-icon rounded-circle bg-green-1 collapse-icon"
                />
              </div>
            </template>
          </div>
          <b-button variant="link" class="mb-auto p-0 border-0" @click="dropNotification(index)">
            <b-icon-x :variant="item.variant" />
          </b-button>
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { BIconServer, BIconExclamationCircle, BIconX, BIconCheckCircle, BIconPatchExclamation } from "bootstrap-vue";
import { GlobalNotificationInterface } from "@/interfaces/globalNotification";
export default Vue.extend({
  name: "NotificationsDisplay",
  components: {
    BIconServer,
    BIconExclamationCircle,
    BIconX,
    BIconCheckCircle,
    BIconPatchExclamation,
  },
  props: {
    hideTypes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    list(): GlobalNotificationInterface[] {
      // this will hide some notifications by type provided in hideTypes prop
      return this.notifications().filter((i: GlobalNotificationInterface) => !this.hideTypes.includes(i.type)) ?? [];
    },
  },
  methods: {
    ...mapGetters({
      notifications: "notifications/items",
    }),
    ...mapActions({
      dropNotification: "notifications/dropNotificationByIndex",
      logOut: "auth/setUserLoggedOut",
      toggleCollapsed: "notifications/toggleCollapsed",
    }),
    formattedText(value: string): string {
      // eslint-disable-next-line
      const formattedValue = value.replace('["', "").replace('"]', "");

      const txt = document.createElement("textarea");
      txt.innerHTML = formattedValue;

      const decodedHTML = txt.value;

      txt.remove();

      return decodedHTML;
    },
    canBeCollapsed(item: any): boolean {
      return item?.collapsed !== undefined;
    },
  },
});
</script>

<style scoped>
.sticky-top {
  background-color: #ffffffc2;
}

.collapse-icon {
  width: 23px !important;
  height: 23px !important;
  position: absolute;
  bottom: -12px;
}

.centered-icon {
  left: calc(50% - 0.6em);
}
</style>
