import ls from "localstorage-slim";
import { LocalStorageSlimSetup } from "@/lib/localStorageSlimParams";

export default function lsInit() {
  ls.config.encrypt = LocalStorageSlimSetup.encrypt;
  ls.config.secret = LocalStorageSlimSetup.secret;
  ls.config.encrypter = LocalStorageSlimSetup.encrypter;
  ls.config.decrypter = LocalStorageSlimSetup.decrypter;

  return ls;
}
