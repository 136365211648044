import { LocalStorageSlimSetup } from "./../lib/localStorageSlimParams";
import ls from "localstorage-slim";
import _Vue from "vue";

const LocalStorageSlim = {
  install(Vue: typeof _Vue): void {
    Vue.prototype.$ls = ls;

    Vue.prototype.$ls.config.encrypt = LocalStorageSlimSetup.encrypt;
    Vue.prototype.$ls.config.secret = LocalStorageSlimSetup.secret;
    Vue.prototype.$ls.config.encrypter = LocalStorageSlimSetup.encrypter;
    Vue.prototype.$ls.config.decrypter = LocalStorageSlimSetup.decrypter;
  },
};

export default LocalStorageSlim;

export interface LocalStorageSlimConfig {
  ttl?: number | null;
  encrypt?: boolean;
  decrypt?: boolean;
  secret?: string;
  encrypter?(data: any, secret: string): string;
  decrypter?(encryptedString: string, secret: string): any;
}

declare module "vue/types/vue" {
  interface Vue {
    $ls: {
      set(key: string, value: any, config?: LocalStorageSlimConfig): void;
      get(key: string, config?: LocalStorageSlimConfig): any;
      flush(force?: boolean): void;
      remove(key: string): void;
      clear(): void;
      config: LocalStorageSlimConfig;
    };
  }
}
