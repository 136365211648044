import Vue from "vue";
import VeeValidate from "vee-validate";
import "@/customizations/validatorRules";

import { validationMessagesLt, validationMessagesEn } from "@/customizations/validationMessages";

Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
  errorBagName: "veeErrors",
  locale: "lt",
  dictionary: {
    en: validationMessagesEn,
    lt: validationMessagesLt,
  },
});
