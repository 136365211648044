import xmlJs from "xml-js";
import { soapActions, SoapBodyParams, SoapErrors } from "./soapParams";
import { create } from "xmlbuilder2";
import { GoodsInterface } from "@/interfaces/application";
import store from "@/store";

class SoapClient {
  /**
   * Sends fetch request and returns json transformed from response XML
   *
   * @param soapAction
   * @param body
   *
   * @returns Promise
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendRequest(soapAction: soapActions, bodyParams: SoapBodyParams, nilFields?: string[]): Promise<any> {
    const bodyParamsClone: Record<string, any> = structuredClone(bodyParams);

    // global data transformation
    if (bodyParamsClone[soapAction]["ns1:paraiska"]) {
      // non namespaced stuff which will be namespaced later, but data should be in proper format already
      if (bodyParamsClone[soapAction]["ns1:paraiska"]?.Refinansavimas?.length) {
        bodyParamsClone[soapAction]["ns1:paraiska"].Refinansavimas = this.prepareSoapRefinansavimas(
          bodyParamsClone[soapAction]["ns1:paraiska"].Refinansavimas
        );
      }

      if (bodyParamsClone[soapAction]["ns1:paraiska"]?.Prekes?.length) {
        bodyParamsClone[soapAction]["ns1:paraiska"].Prekes = this.prepareSoapPrekes(bodyParamsClone[soapAction]["ns1:paraiska"].Prekes);
      }

      // already namespaces stuff
      if (bodyParamsClone[soapAction]["ns1:paraiska"]["ns1:Refinansavimas"]?.length) {
        bodyParamsClone[soapAction]["ns1:paraiska"]["ns1:Refinansavimas"] = this.prepareSoapRefinansavimas(
          bodyParamsClone[soapAction]["ns1:paraiska"]["ns1:Refinansavimas"]
        );
      }

      if (bodyParamsClone[soapAction]["ns1:paraiska"]["ns1:Prekes"]?.length) {
        bodyParamsClone[soapAction]["ns1:paraiska"]["ns1:Prekes"] = this.prepareSoapPrekes(
          bodyParamsClone[soapAction]["ns1:paraiska"]["ns1:Prekes"]
        );
      }
    }

    const doc = create({ keepNullNodes: true, encoding: "UTF-8" })
      .ele("SOAP-ENV:Envelope")
      .att("xmlns:ns1", "http://tempuri.org/")
      .att("xmlns:xsi", "http://www.w3.org/2001/XMLSchema-instance")
      .att("xmlns:SOAP-ENV", "http://schemas.xmlsoap.org/soap/envelope/")
      .ele("SOAP-ENV:Body")
      .ele(this._applyNameSpace(this._nullToUndefined(bodyParamsClone), "ns1:"));

    const options = {
      body: doc.end(),
    };

    // try to put xsi:nil="true" for nil specific fields
    if (nilFields && nilFields.length) {
      nilFields.forEach((element) => {
        // this is just a find/replace if value is ""
        if (!bodyParamsClone[soapAction][element] || bodyParamsClone[soapAction][element].length == 0) {
          options.body = options.body.replace(`<${element}/>`, `<${element} xsi:nil="true"/>`);
        }
      });
    }

    return new Promise((resolve, reject) => {
      try {
        fetch(process.env.VUE_APP_UBLVKS_URL || "", {
          credentials: "omit",
          headers: new Headers({
            Connection: "Keep-Alive",
            "Content-Type": "text/xml; charset=utf-8",
            SOAPAction: `http://tempuri.org/${soapAction.replace("ns1:", "")}`,
          }),
          method: "POST",
          mode: "cors",
          ...options,
        })
          .then((response) => {
            // this part is still not ready to be used due to data mapping
            // @todo: fix this and then drop xml-js dependency completely
            // create object from xml response
            // const resultObj: any = create(xml).end({ format: "object" });
            // let resolved = null;
            // let responseKeys = null;
            // let resultKeys = null;
            // if (resultObj && resultObj["soap:Envelope"]["soap:Body"]) {
            //   responseKeys = Object.keys(resultObj["soap:Envelope"]["soap:Body"]);
            //   resultKeys = Object.keys(resultObj["soap:Envelope"]["soap:Body"][responseKeys[0]]).filter(
            //     (k: string) => k.indexOf("@") !== 0
            //   );
            //   resolved = resultObj["soap:Envelope"]["soap:Body"][responseKeys[0]][resultKeys[0]];
            // }
            // console.log("xml out", xml);
            // console.log(resolved, responseKeys, resultKeys);

            if (response.ok) {
              response
                .text()
                .then((xml) => {
                  const resolved = this._nullify(this._xmlResponseToJson(xml));

                  // main session expiration check
                  // keep in mind that some actions will be allowed even if session is expired
                  if (+resolved.KlaidosKodas === +SoapErrors.INCORRECT_SESSION) {
                    store.dispatch("auth/setSessionExpired", true).then(() => {
                      store.dispatch("auth/setUserLoggedOut");
                      reject(resolved);
                    });

                    return;
                  }

                  // check if there is error code in resolved response and reject it
                  // this will give more universal promise....catch control
                  if (+resolved.KlaidosKodas > 0) {
                    reject(resolved);

                    return;
                  }

                  // all went ok so just pass the result
                  resolve(resolved);

                  // and exit here
                  return;
                })
                .catch((error) => {
                  // looks like there are problems when parsing the response body as text
                  reject(error);
                });

              return;
            }

            // it means request rejected on the server
            reject(response);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  private _applyNameSpace(obj: any, namespace = "") {
    if (!namespace.length) {
      return obj;
    }

    if (typeof obj !== "object" || !obj) {
      return false; // check the obj argument somehow
    }

    const arrayMode = Array.isArray(obj);

    const keys = Object.keys(obj);
    const keysLen = keys.length;

    for (let i = 0; i < keysLen; i++) {
      let notFound = false;
      if (!arrayMode && keys[i].indexOf(namespace) === -1) {
        obj[namespace + keys[i]] = obj[keys[i]];
        notFound = true;
      }

      if (typeof obj[keys[i]] === "object" && !Array.isArray(keys[i]) && keys[i] !== null) {
        if (notFound) {
          obj[namespace + keys[i]] = this._applyNameSpace(obj[namespace + keys[i]], namespace);
        } else {
          obj[keys[i]] = this._applyNameSpace(obj[keys[i]], namespace);
        }
      }

      if (!arrayMode && notFound) {
        delete obj[keys[i]];
      }
    }

    return obj;
  }

  /**
   * Transforms null values to undefined in any nested obj
   *
   * @param obj any
   * @returns transformed obj
   */
  private _nullToUndefined(obj: any) {
    if (typeof obj !== "object" || !obj) {
      return undefined;
    }

    const arrayMode = Array.isArray(obj);

    const keys = Object.keys(obj);
    const keysLen = keys.length;

    for (let i = 0; i < keysLen; i++) {
      if (!arrayMode && obj[keys[i]] === null) {
        obj[keys[i]] = undefined;
      }

      if (typeof obj[keys[i]] === "object" && !Array.isArray(keys[i]) && keys[i] !== null) {
        obj[keys[i]] = this._nullToUndefined(obj[keys[i]]);
      }
    }

    return obj;
  }

  /**
   * Helper for transforming XML text value
   * @param obj
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _fixXmlTextValue(obj: { [x: string]: any }) {
    for (const prop in obj) {
      if (typeof obj[prop] === "object") {
        if ("_text" in obj[prop]) {
          obj[prop] = obj[prop]._text;
        } else {
          this._fixXmlTextValue(obj[prop]);
        }
      }
    }

    return obj;
  }

  /**
   * Helper for transforming XML response into JSON
   *
   * @param response
   */
  private _xmlResponseToJson(xml: string) {
    const data = this._fixXmlTextValue(
      xmlJs.xml2js(xml, {
        compact: true,
        nativeType: false, // if true then values like <AsmensKodas>000000000</AsmensKodas> gets converted into 0, else -> string "000000000"
        trim: true,
      })
    );

    const responseData = data["soap:Envelope"]["soap:Body"];

    const responseKey = Object.keys(responseData).find((key) => key.match(/Response/));

    if (!responseKey) {
      return responseData;
    }

    const resultKey = Object.keys(responseData[responseKey]).find((key) => key.match(/Result/));

    if (!resultKey) {
      return responseData;
    }

    const actual = responseData[responseKey][resultKey];

    if (actual.KlaidosKodas > 0) {
      return actual;
    }

    return actual;
  }

  /**
   * This will transform empty objects to null
   * @param obj
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _nullify(obj: { [x: string]: any; constructor: ObjectConstructor }) {
    if (!obj || (obj.constructor === Object && !Object.keys(obj).length)) {
      return obj;
    }

    for (const key in obj) {
      if (JSON.stringify(obj[key]) == "{}") {
        obj[key] = null;
      } else if (typeof obj[key] == "object" && !Date.parse(obj[key])) {
        obj[key] = this._nullify(obj[key]);
      }
    }

    return obj;
  }

  private refinansavimasToXml(input: any): string {
    const out: any[] = [];
    Object.keys(input).forEach((k) => {
      if (typeof input[k] === "object") {
        out.push(`<loan>${this.refinansavimasToXml(input[k])}</loan>`);
      } else {
        out.push(`<${k}>${input[k]}</${k}>`);
      }
    });

    return out.join("");
  }

  private prepareSoapRefinansavimas(refinance: any): string {
    if (!refinance) {
      return "";
    }

    if (!Array.isArray(refinance)) {
      return refinance;
    }

    return "<loans>" + this.refinansavimasToXml(refinance) + "</loans>";
  }
  private prepareSoapPrekes(prekes?: GoodsInterface[]): object | string {
    if (prekes == undefined) {
      return "";
    }

    const result: object[] = [];

    prekes?.forEach((element) => {
      result.push({
        "ns1:PrekesID": element.id || 0,
        "ns1:Pavadinimas": element.title,
        "ns1:Kiekis": element.quantity,
        "ns1:Kaina": element.price,
        "ns1:Apdrausta": +element.insured,
        "ns1:TurtoRusiesID": element.group,
        "ns1:SerijosNr": element.serialNo,
        "ns1:GarantijosTrukme": element.warrantySelected,
        "ns1:GamintojoGarantija": element.warranty,
        "ns1:GarantijosKaina": element.warrantySum,
        "ns1:Apribojimai": element.limitations || null,
      });
    });

    return { "ns1:Preke": result };
  }
}

export default SoapClient;
