import Vue from "vue";

const featureDetection = Vue.extend({
  computed: {
    isTest() {
      return process.env.NODE_ENV === "test";
    },
    isTouchDevice: function (): boolean {
      return "ontouchstart" in window || navigator.maxTouchPoints > 0; // || navigator.msMaxTouchPoints > 0;
    },
    isIE: function (): boolean {
      const userAgent = navigator.userAgent;
      return userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1 || userAgent.indexOf("Edge/") > -1;
    },
    isMobile: function (): boolean {
      return navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i) ? true : false;
    },
    isFF: function (): boolean {
      return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
  },
});

export default featureDetection;
