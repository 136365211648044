export enum timeActions {
  timeNow = "/time/now/:offset",
}

export interface TimeRequestParams {
  [timeActions.timeNow]?: BaseParamInterface;
}

export interface BaseParamInterface {
  offset?: number;
}
