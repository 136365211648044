export interface SontTokenInterface {
  expires_in: number;
  access_token: string;
  scope: string;
  token_type: string;
}

export interface SontUserInterface {
  id: string;
  name: string;
  email: string;
  username: string;
  deleted: boolean;
  signature?: string;
  externalToken?: string;
  unit?: string;
  viewUnit?: boolean;
}

export interface SontSignRequestResponse {
  callbackUrl: null | string;
  clientUser: string;
  documents: SontDocument[];
  id: string;
  name: null | string;
  request: string;
  status: SontRequestStatuses;
}

export interface SontDocument {
  id: string;
  signatures: unknown;
  name: string;
  content: string;
  digest: string;
  status: SontRequestStatuses;
  eseal: boolean;
  eid: boolean;
  metadata: unknown[];
  biometricValidation: string;
  eidType: null | string | boolean;
  eidCode: null | string | boolean;
  photos: null | string | [];
}

export enum SontRequestStatuses {
  PENDING = "pending",
  CANCELED = "canceled",
  SIGNED = "signed",
}
export enum SontSendingRequestStatuses {
  PENDING = 0,
  FAILED = 1,
  SUCCESS = 2,
}

export interface SontSignStatusInterface {
  id: string;
  status: string;
}
