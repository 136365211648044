<template>
  <div class="w-100 vh-100 d-flex justify-content-center bg-sand-2 p-md-0">
    <div class="my-auto position-relative w-100">
      <div v-if="!isLoadedInIframe" class="text-right logo-margin">
        <img :data-version="appReleaseForTracking" class="logo img-responsive" src="../assets/logo.svg" />
      </div>
      <b-card class="bg-sand-2 p-3 p-md-5 rounded-0 border-0">
        <b-alert variant="danger" v-model="loginError">
          <span v-if="loginErrorCode == 1004 || loginErrorCode == 1005" v-html="$t(`soap.${loginErrorCode}`)"></span>
          <span v-else>{{ $t(`soap.${loginErrorCode}`) | capitalize }}</span>
          <span class="small" v-if="loginErrorCode !== networkErrorCode"><br />{{ loginErrorMessage }}</span>
        </b-alert>
        <h1 class="h3 mb-4 color-gray-5">
          {{ $t("iframeLogin.formTitle") | capitalize }}
        </h1>
        <b-form variant="light">
          <b-form-group label-class="mb-0 color-gray-5 font-weight-bold" label-for="userName" :label="$t('login.userName') | capitalize">
            <b-input
              id="userName"
              class="py-4 border-0 rounded"
              v-model="userName"
              @keyup.enter="doLogin(userName, userPassword)"
            ></b-input>
          </b-form-group>
          <b-form-group label-class="mb-0 color-gray-5 font-weight-bold" label-for="pass" :label="$t('login.password') | capitalize">
            <b-input-group>
              <b-input
                id="pass"
                :type="passwordType"
                class="py-4 border-0 rounded"
                v-model="userPassword"
                @keyup.enter="doLogin(userName, userPassword)"
              ></b-input>
              <password-toggler
                v-model="passwordType"
                css-classes="rounded border-0 border-left-0 top-left-no-round bottom-left-no-round cursor-pointer bg-white"
              ></password-toggler>
            </b-input-group>
          </b-form-group>
          <b-form-group class="text-right">
            <b-link class="color-black" @click="toPasswordRemind">
              {{ $t("iframeLogin.passwordRemindLink") | capitalize }}
            </b-link>
          </b-form-group>
          <b-button
            id="btn-login"
            variant="primary"
            class="w-100 text-white btn-sm p-3 rounded-more"
            @click="doLogin(userName, userPassword)"
            >{{ $t("login.login") | capitalize }}</b-button
          >
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { routerPaths } from "@/router/routerPaths";
import validation from "@/mixins/validation";
import login from "@/mixins/login";
import NotificationsDisplay from "@/components/notificationsDisplay.vue";
import PasswordToggler from "@/components/passwordToggler.vue";
import featureDetection from "@/mixins/featureDetection";
import releaseVersion from "@/mixins/releaseVersion";
import CountDown from "@/components/countDown.vue";
import AccountUpdateForm from "@/components/AccountUpdateForm.vue";
import { md5 } from "@/lib/md5Generator";

export default mixins(validation, login, featureDetection, releaseVersion).extend({
  name: "IframeLogin",
  components: {
    NotificationsDisplay,
    PasswordToggler,
    CountDown,
    AccountUpdateForm,
  },
  data() {
    return {
      sendingFailed: false,
      userName: "",
      userPassword: "",
      passwordType: "password",
      form: {
        verificationCode: "",
      },
      codeConfirmed: "",
      isLoading: false,
      deviceVerificationPerformed: false,
      triedTimes: 0,
      tryLater: false,
      tryLaterTimeout: null as any,
      twoFaVerificationToken: null as null | string,
      isLoadedInIframe: false,
      urlAfterLogin: "",
      loginInProgress: false,
    };
  },
  mounted() {
    this.urlAfterLogin = process.env.VUE_APP_PUBLIC_URL || "";
    if (this.inIframe) {
      this.isLoadedInIframe = true;
    }
  },
  methods: {
    doLogin(userName: string, userPassword: string) {
      top?.location.replace(`${this.urlAfterLogin}${routerPaths.AUTO_LOGIN}?u=${userName}&p=${md5(userPassword)}&r=news`);
      this.loginInProgress = true;
    },
    toPasswordRemind() {
      top?.location.replace(`${this.urlAfterLogin}${routerPaths.PASSWORD_REMIND}`);
    },
  },
});
</script>

<style scoped>
.logo-margin {
  margin-top: 10px;
}
</style>
