import { GlobalNotificationInterface } from "@/interfaces/globalNotification";
import { SoapErrors } from "@/lib/soap/soapParams";
import he from "he";
import Vue from "vue";
import { Module, ActionContext } from "vuex";

/**
 * Simple <a href=...>...</a> extractor
 * @param htmlMessage string | undefined - html entity encoded string
 * @returns string | undefined - string with hrefs replaced to non encoded format
 */
const _prepareHtmlMessages = (htmlMessage: string | undefined): string | undefined => {
  if (!htmlMessage) {
    return htmlMessage;
  }

  let htmlMessageTransformed = "";
  if (htmlMessage.indexOf(" href=") !== -1) {
    // should match all html entity encoded links (a href) with any parameters and will extract only url and link text
    const hrefPattern = /&lt;a(?: .*?)?href=(.*?)(?:(?: .*?))?(?:&gt;)(.*?)(?:&lt);\/a&gt;/gm;
    const matched = [...htmlMessage.matchAll(hrefPattern)];
    let generatedHtml = htmlMessage;
    if (matched.length) {
      matched.forEach((element) => {
        generatedHtml = generatedHtml.replace(
          element[0],
          `<a href="${element[1]}" target="_blank" rel="noopener noreferrer">${element[2]}</a>`
        );
      });
      htmlMessageTransformed = generatedHtml;
    }
  } else {
    htmlMessageTransformed = htmlMessage;
  }

  return he.unescape(htmlMessageTransformed);
};

// state
export const state = {
  keepNotificationsOnRouteChange: false,
  items: [] as GlobalNotificationInterface[],
};

// getters
export const getters = {
  keepNotificationsOnRouteChangeOnce: (state: { keepNotificationsOnRouteChange: boolean }) => state.keepNotificationsOnRouteChange,
  items: (state: { items: GlobalNotificationInterface[] }) => state.items,
  soapErrorCount: (state: { items: GlobalNotificationInterface[] }) => {
    return state.items.filter((item) => item.type === "soap").length;
  },
};

// mutations
export const mutations = {
  setKeepNotificationsOnRouteChangeOnce(state: { keepNotificationsOnRouteChange: boolean }, payload: { keep: boolean }) {
    state.keepNotificationsOnRouteChange = payload.keep;
  },
  resetItems(state: { items: GlobalNotificationInterface[] }) {
    // unique items should ignore this
    state.items = state.items.filter((item) => item.unique);
  },
  setItems(state: { items?: GlobalNotificationInterface[] }, item: GlobalNotificationInterface) {
    if (item.unique && state.items?.filter((i) => i.type === item.type).length) {
      return;
    }

    state.items?.push(item);
  },
  removeNotificationByIndex(state: { items: GlobalNotificationInterface[] }, index: number) {
    const leftItems = state.items;
    leftItems.splice(index, 1);
    state.items = leftItems;
  },
  setItemCollapse(state: { items: GlobalNotificationInterface[] }, payload: GlobalNotificationInterface) {
    if (payload?.index === undefined) return;

    const items = state.items;
    let selectedItem = state.items[payload.index];

    if (selectedItem?.collapsed === undefined) return;

    selectedItem = { ...selectedItem, collapsed: payload.collapsed };

    Vue.set(items, payload.index, selectedItem);

    state.items = items;
  },
};

// actions
export const actions = {
  setKeepNotificationsOnRouteChangeOnce({ commit }: ActionContext<typeof state, any>, payload?: boolean) {
    commit("setKeepNotificationsOnRouteChangeOnce", { keep: payload });
  },
  showWarning({ commit }: ActionContext<typeof state, any>, payload?: string | number) {
    commit("setItems", {
      message: payload,
      type: "general",
      variant: "warning",
    });
  },
  showHtmlWarning({ commit }: ActionContext<typeof state, any>, payload?: string) {
    commit("setItems", {
      htmlMessage: _prepareHtmlMessages(payload),
      type: "general",
      variant: "warning",
    });
  },
  showError({ commit }: ActionContext<typeof state, any>, payload?: string | number) {
    commit("setItems", {
      message: payload,
      type: "general",
      variant: "danger",
    });
  },
  showHtmlError({ commit }: ActionContext<typeof state, any>, payload?: string) {
    commit("setItems", {
      htmlMessage: _prepareHtmlMessages(payload),
      type: "general",
      variant: "danger",
    });
  },
  showSoapError(
    { commit, dispatch }: ActionContext<typeof state, any>,
    payload?: { KlaidosKodas: string | number; KlaidosPranesimas: string }
  ) {
    commit("setItems", {
      code: payload?.KlaidosKodas,
      text: payload?.KlaidosPranesimas,
      type: "soap",
      variant: "danger",
    });

    // force logout if error code is for incorrect session
    if (payload?.KlaidosKodas == SoapErrors.INCORRECT_SESSION) {
      // with timeout that user could see the error first and redirect after
      setTimeout(() => {
        dispatch("auth/setUserLoggedOut", {}, { root: true });
      }, 1000);
    }
  },
  showSessionExpiredError({ commit }: ActionContext<typeof state, any>, sessionTimeout: number) {
    commit("setItems", {
      sessionTimeout: sessionTimeout,
      type: "sessionExpired",
      variant: "danger",
      unique: true,
    });
  },
  showSuccess({ commit }: ActionContext<typeof state, any>, payload?: string | number) {
    commit("setItems", {
      message: payload,
      type: "greeting",
      variant: "success",
    });
  },
  showHtmlSuccess({ commit }: ActionContext<typeof state, any>, payload?: string | GlobalNotificationInterface) {
    const isObject = typeof payload === "object";

    commit("setItems", {
      message: isObject ? payload?.message : "",
      htmlMessage: _prepareHtmlMessages(isObject ? payload.htmlMessage : payload),
      type: "greeting",
      variant: "success",
      collapsed: isObject ? payload?.collapsed : undefined,
    });
  },
  showInfo({ commit }: ActionContext<typeof state, any>, payload?: string | number) {
    commit("setItems", {
      message: payload,
      type: "attention",
      variant: "info",
    });
  },
  showHtmlInfo({ commit }: ActionContext<typeof state, any>, payload?: string) {
    commit("setItems", {
      htmlMessage: _prepareHtmlMessages(payload),
      type: "attention",
      variant: "info",
    });
  },
  dropAll({ commit }: ActionContext<typeof state, any>) {
    commit("resetItems");
  },
  dropNotificationByIndex({ commit }: ActionContext<typeof state, any>, payload: number) {
    commit("removeNotificationByIndex", payload);
  },
  toggleCollapsed({ commit }: ActionContext<typeof state, any>, payload: GlobalNotificationInterface) {
    commit("setItemCollapse", payload);
  },
};

const notificationsModule: Module<any, any> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default notificationsModule;
