/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GlobalStorageKeys } from "@/interfaces/applicationSettings";
import { AutoRefreshRoutes } from "@/router/routerPaths";
import mixins from "vue-typed-mixins";
import featureDetection from "./featureDetection";

const swUpdate = mixins(featureDetection).extend({
  data() {
    return {
      // refresh variables
      refreshing: false,
      registration: null as null | any,
      updateExists: false,
      swReady: false,
    };
  },

  created() {
    if (process.env.NODE_ENV !== "production" || this.inIframe) {
      // service workers won't work without https and prod mode
      // also we want to skip this process if app is loaded in iframe
      return;
    }

    // Listen for our custom event from the SW registration
    // @ts-ignore: unreachable code
    document.addEventListener("swRegistered", this.swIsReady, { once: true });

    // @ts-ignore: unreachable code
    document.addEventListener("swUpdated", this.updateAvailable, { once: true });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      // @ts-ignore: unreachable code
      if (this.refreshing) return;
      // @ts-ignore: unreachable code
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },

  methods: {
    // this should be called always when service worker is properly started and ready to be used
    swIsReady() {
      this.swReady = true;
    },

    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event: { detail: null }) {
      this.registration = event.detail;
      this.updateExists = true;
      const mapAutoRefreshRouteToPath = AutoRefreshRoutes.some(
        (item) => this.$route.path.indexOf(item) !== -1 || this.$route.path.indexOf(item.split(":")[0]) !== -1
      );

      if (localStorage.getItem(GlobalStorageKeys.SW_SKIP_WAITING)) {
        this.refreshApp();

        return;
      }

      if (mapAutoRefreshRouteToPath) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });

        return;
      }

      // this will be used on next app refresh
      localStorage.setItem(GlobalStorageKeys.SW_SKIP_WAITING, "1");
    },

    // Called when the user accepts the update or when we force to do so
    refreshApp() {
      if (!this.swReady || !localStorage.getItem(GlobalStorageKeys.SW_SKIP_WAITING)) {
        return;
      }

      localStorage.removeItem(GlobalStorageKeys.SW_SKIP_WAITING);

      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },
});

export default swUpdate;
