/* eslint-disable @typescript-eslint/ban-ts-comment */
import Vue from "vue";

const validation = Vue.extend({
  methods: {
    clearAndRevalidate() {
      // @ts-ignore: unreachable code
      this.veeErrors.clear();
      this.validateInputs();
    },
    validateInputs() {
      this.$validator.validateAll();
    },
    validateState(field: string, scope?: string): boolean {
      if (scope && field) {
        const validatedField = this.$validator.fields.find({ name: field, scope: scope });
        if (validatedField && (validatedField?.flags.dirty || validatedField?.flags.validated)) {
          // @ts-ignore: unreachable code
          return !this.veeErrors.has(field, scope);
        }
      } else {
        // @ts-ignore: unreachable code
        if (this.veeFields[field] && (this.veeFields[field].dirty || this.veeFields[field].validated)) {
          // @ts-ignore: unreachable code
          return !this.veeErrors.has(field);
        }
      }

      return true;
    },
    isRuleValid(field: string, rule: string): boolean {
      return !this.$validator.errors.items.some((item) => item.field === field && item.rule === rule);
    },
    isScopeValid(scope: string): boolean {
      return !this.$validator.errors.items.some((item) => item.scope === scope);
    },
    inputMinLength(data: string): boolean {
      return data.length >= 8;
    },
    inputHasNumber(data: string): boolean {
      return /\d/.test(data);
    },
    inputHasLoweCase(data: string): boolean {
      return /[a-z]/.test(data);
    },
    inputHasUpperCase(data: string): boolean {
      return /[A-Z]/.test(data);
    },
    inoutHasSpecialCase(data: string): boolean {
      return /[(!"#$%&'()*+,-./:;<=>?@[\]_`{}~)]/.test(data);
    },
    strongPasswordExist(password: string): boolean {
      const hasMinLength = this.inputMinLength(password);
      const hasNumber = this.inputHasNumber(password);
      const hasLowerCase = this.inputHasLoweCase(password);
      const hasUpperCase = this.inputHasUpperCase(password);
      const hasSpecialCase = this.inoutHasSpecialCase(password);

      return hasMinLength && hasNumber && hasLowerCase && hasUpperCase && hasSpecialCase;
    },
    passwordErrors(password = "" as string): string {
      const minLengthRule = !this.inputMinLength(password)
        ? `<span>${this.$t("errors.lengthRule")} <strong>${this.$t("errors.lengthRuleValue")}</strong></span> <br>`
        : "";
      const hasLowerCaseRule = !this.inputHasLoweCase(password)
        ? `<span>${this.$t("errors.caseRule")} <strong>${this.$t("errors.lowerCaseRuleValue")}</strong></span> <br>`
        : "";
      const hasUpperCaseRule = !this.inputHasUpperCase(password)
        ? `<span>${this.$t("errors.caseRule")} <strong>${this.$t("errors.capitalCaseRuleValue")}</strong></span> <br>`
        : "";
      const hasNumberRule = !this.inputHasNumber(password)
        ? `<span>${this.$t("errors.specialCharRule")} <strong>${this.$t("errors.numberRuleValue")}</strong></span> <br>`
        : "";
      const hasSpecialCaseRule = !this.inoutHasSpecialCase(password)
        ? `
        <span>${this.$t("errors.specialCharRule")}
        <strong>${this.$t("errors.specialCaseRuleValue")}</strong> (!"#$%&'()*+,-./:;<=>?@[]^_\`{|}~)</span>`
        : "";

      return minLengthRule + hasLowerCaseRule + hasUpperCaseRule + hasNumberRule + hasSpecialCaseRule;
    },
    getPersonalCodeRegex(code: number | string) {
      return this.isPersonCodeWhitelisted(code) ? /\d/ : this.constantPersonCodeRegex;
    },
    isPersonCodeWhitelisted(code: number | string) {
      const personCode = (code || "").toString();

      return this.whitelistedCodes.includes(personCode);
    },
    isValidPersonalCode(code: string | null) {
      if (!code || code?.toString()?.length != 11) {
        return false;
      }

      if (this.isPersonCodeWhitelisted(code)) {
        return true;
      }

      let i, a;
      let b = 1,
        c = 3,
        d = 0,
        e = 0;

      const s = new String(code);

      for (i = 0; i < 10; i++) {
        a = s.charAt(i);
        d = d + b * +a;
        e = e + c * +a;
        b = b + 1;
        c = c + 1;

        if (b == 10) {
          b = 1;
        }

        if (c == 10) {
          c = 1;
        }
      }

      d = d % 11;
      e = e % 11;
      i = d;

      if (d == 10) {
        i = e == 10 ? 0 : e;
      }

      return s.toString().charAt(10) == i.toString();
    },
  },
  computed: {
    constantPersonCodeRegex: () => {
      return /^[3-6][0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-2])[0-9]{4}$/;
    },
    constantIbanRegex: () => {
      // eslint-disable-next-line
      return /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{18}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i;
    },
    constantPhoneRegex: () => {
      return /^6\d{7}/;
    },
    constantDateRangeRegex: () => {
      return /^\d{4}-((0[1-9])|(1[0-2]))-((0[1-9])|(1[0-2])|2[0-9]|3[0-1])-\d{4}-((0[1-9])|(1[0-2]))-((0[1-9])|(1[0-2])|2[0-9]|3[0-1])$/;
    },
    whitelistedCodes: () => {
      const whitelistedCodes = process.env.VUE_APP_ALLOWED_PERSON_CODES || "";

      return whitelistedCodes.split(",");
    },
  },
});

export default validation;
