import Vue from "vue";
import { AuthenticationMethods } from "@/interfaces/applicationSettings";

const authenticationOptions = Vue.extend({
  data() {
    return {
      AuthenticationMethods,
    };
  },
  computed: {
    authenticationOptions: function (): { text: string; value: string }[] {
      return [
        {
          text: this.$options.filters?.capitalize(this.$t("accountSettings.authenticationUndecided")),
          value: AuthenticationMethods.NONE.toString(),
        },
        {
          text: this.$options.filters?.capitalize(this.$t("accountSettings.authenticationByEmail")),
          value: AuthenticationMethods.EMAIL.toString(),
        },
        {
          text: this.$options.filters?.capitalize(this.$t("accountSettings.authenticationBySMS")),
          value: AuthenticationMethods.SMS.toString(),
        },
      ];
    },
  },
});

export default authenticationOptions;
