import { SellerAuthentication } from "@/interfaces/seller";

export enum userVerifyActions {
  sendSms = "/user-verify/send-sms",
  verifyCode = "/user-verify/verify-code",
  changePassword = "/user-verify/change-password",
  requestVerificationEmail = "/user-verify/remind-password",
  deviceCheck = "/user-verify/device-check",
  deviceCheckTime = "/user-verify/device-check-time",
  deviceRegister = "/user-verify/device-register",
  contactVerification = "/user-verify/verify-contacts",
  verifyContactsCode = "/user-verify/verify-contacts-code",
  accountUpdate = "/user-verify/account-update-code",
  accountUpdateVerifyCode = "/user-verify/account-update-verify-code",
  accountUpdateRecheckDevice = "/user-verify/recheck-device",
  sendingStatus = "/user-verify/sending-status/{id}",
}

export enum PasswordRemindRequestCodes {
  PENDING = 0,
  ERROR = 1,
  SUCCESS = 2,
  TRY_LATER = 3,
  PROVIDE_LOGIN = 4,
}

export interface UserVerifyRequestParams {
  [userVerifyActions.sendSms]?: SendSmsRequestParamsInterface;
}

export interface SendSmsRequestParamsInterface {
  phone: string;
  locale: string;
}

export interface VerifyContactsRequestParams {
  vartotojoVardas: string;
  metodas: SellerAuthentication | number;
  Emailas?: string;
  MobilusTelefonas?: string;
  sesija: string;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.accountUpdate]?: AccountUpdateRequestParams;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.accountUpdateVerifyCode]?: VerifyContactsRequestParamsInterface;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.accountUpdateRecheckDevice]?: AccountUpdateRecheckDeviceRequestParamsInterface;
}

export interface AccountUpdateRecheckDeviceRequestParamsInterface {
  sesija: string;
  token: string;
  metodas: SellerAuthentication | number;
}

export interface AccountUpdateRequestParams {
  vartotojoVardas: string;
  metodas: SellerAuthentication | number;
  Emailas?: string;
  MobilusTelefonas?: string;
  sesija: string;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.contactVerification]?: VerifyContactsRequestParams;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.verifyCode]?: UserVerifyRequestParamsInterface;
}

export interface UserVerifyRequestParamsInterface {
  token: string;
  code: string;
  metodas: SellerAuthentication | number;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.verifyContactsCode]?: VerifyContactsRequestParamsInterface;
}

export interface VerifyContactsRequestParamsInterface {
  token: string;
  code: string;
  metodas: SellerAuthentication | number;
  sesija: string;
}

export interface SendSmsResponseInfoInterface {
  code: number;
  msg: string;
  wait?: number;
}

export interface SendSmsResponseInterface {
  info: SendSmsResponseInfoInterface;
  status: boolean;
  requestID?: string;
  token?: string;
  login?: string;
  verified?: string;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.changePassword]?: ChangePasswordRequestParamsInterface;
}

export interface ChangePasswordRequestParamsInterface {
  token: string;
  password: string;
  login?: string;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.requestVerificationEmail]?: ResetPasswordEmailParamsInterface;
}

export interface ResetPasswordEmailParamsInterface {
  email: string;
  login?: string;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.deviceCheck]?: DeviceCheckParamsInterface;
}
export interface DeviceCheckParamsInterface {
  vartotojoVardas: string;
  sesija: string;
  irenginioId: string;
  metodas: SellerAuthentication;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.deviceCheckTime]?: DeviceCheckTimeParamsInterface;
}

export interface DeviceCheckTimeParamsInterface {
  vartotojoVardas: string;
  sesija: string;
}

export interface UserVerifyRequestParams {
  [userVerifyActions.deviceRegister]?: DeviceRegisterParamsInterface;
}

export interface DeviceRegisterParamsInterface {
  sesija: string;
  token: string;
  code: string;
}

export interface DeviceVerificationEntryInterface {
  verified: boolean;
  deviceId?: string;
}
