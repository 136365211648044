<template>
  <div class="d-lg-inline-flex px-lg-3">
    <b-badge
      class="mt-auto m-1 mb-3 cursor-pointer"
      :variant="variantByStatus(service.Statusas)"
      v-for="service in services"
      :key="service.SistemosID"
      v-b-popover.hover.top="formatDate(service.Atnaujinta)"
      :href="service.Url"
      target="_blank"
      >{{ service.Pavadinimas }}</b-badge
    >
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { ServiceInterface, ServiceStatus } from "@/interfaces/applicationSettings";
import { format } from "date-fns";

export default Vue.extend({
  name: "ServiceStatuses",
  props: {
    services: {
      type: Array as PropType<ServiceInterface[]>,
      default: () => [],
    },
  },
  methods: {
    variantByStatus(status: string | number): string {
      let result = "secondary";
      switch (+status) {
        case ServiceStatus.OK:
          result = "success border border-success";
          break;
        case ServiceStatus.SLOW:
          result = "gold border border-sand-5 bg-sand-5";
          break;
        case ServiceStatus.ERROR:
          result = "danger border border-danger";
          break;
      }

      return result;
    },
    formatDate(dateTime: string): string {
      return format(new Date(dateTime), "yyyy-MM-dd HH:mm:ii");
    },
  },
});
</script>
