import { Validator } from "vee-validate";

// custom validation rule to check if value is in allowed values array
const isInList = (value: string, { list = [] } = {}): boolean => {
  return Array.isArray(list) && list.find((item: string) => item.toString().toLowerCase() === value.toString().toLowerCase()) !== undefined;
};
const paramNames = ["list"];
Validator.extend(
  "inList",
  {
    getMessage: (field: string) => "Error on " + field,
    validate: isInList,
  },
  {
    paramNames,
  }
);

const validPersonalCode = (value: string, { whitelist = [""] } = {}): boolean => {
  if (!value || value?.toString()?.length != 11) {
    return false;
  }

  if (whitelist.includes(value)) {
    return true;
  }

  let i, a;
  let b = 1,
    c = 3,
    d = 0,
    e = 0;

  const s = new String(value);

  for (i = 0; i < 10; i++) {
    a = s.charAt(i);
    d = d + b * +a;
    e = e + c * +a;
    b = b + 1;
    c = c + 1;

    if (b == 10) {
      b = 1;
    }

    if (c == 10) {
      c = 1;
    }
  }

  d = d % 11;
  e = e % 11;
  i = d;

  if (d == 10) {
    i = e == 10 ? 0 : e;
  }

  return s.toString().charAt(10) == i.toString();
};
Validator.extend(
  "validPersonalCode",
  {
    getMessage: (field: string) => "Error on " + field,
    validate: validPersonalCode,
  },
  {
    paramNames: ["whitelist"],
  }
);

const isValueValid = (value: string, { valid = false } = {}): boolean => {
  return !!valid;
};
Validator.extend(
  "isAccepted",
  {
    getMessage: (field: string) => "Error on " + field,
    validate: isValueValid,
  },
  {
    paramNames: ["valid"],
  }
);

const isStringAllowed = (value: string): boolean => {
  const allowedRegex = /^[A-Za-zĄąČčĖėĘęĮįŲųŪūŠšŽž\s-]+$/;
  return allowedRegex.test(value);
};
Validator.extend("isStringAllowed", {
  getMessage: (field: string) => "Error on " + field,
  validate: isStringAllowed,
});

const emailFirstPart = (value: string): boolean => {
  if (!value) {
    return false;
  }

  // check only wat is behind of @ because tld is already verified by email validation rule
  value = value.toString().split("@")[0];

  if (!value) {
    return false;
  }

  if (!/^[\w-.]+$/.test(value)) {
    return false;
  }

  return true;
};
Validator.extend("emailFirstPart", {
  getMessage: (field: string) => "Error on " + field,
  validate: emailFirstPart,
});
