import Vue from "vue";

const releaseVersion = Vue.extend({
  data() {
    return {
      appEnv: "",
      version: "",
    };
  },

  created() {
    this.appEnv = process.env.VUE_APP_PACKAGE_VERSION || "";
    this.version = process.env.VUE_APP_VERSION || "";
  },

  computed: {
    appReleaseForTracking(): string {
      return `${this.appEnv}-${this.version}`;
    },
  },
});

export default releaseVersion;
