const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const validationMessagesEn = {
  custom: {
    amount: {
      required: () => " ",
      min_value: (field: string, min: number) => `The ${field} field must be ${Number(min).toFixed(2)} or more`,
    },
    authenticationMethod: {
      excluded: (field: string) => `The field ${field} is required`,
    },
    passwordConfirmation: {
      confirmed: () => "Passwords do not match",
    },
    documentType: {
      included: (field: string) => `Field ${field} is required`,
    },
    spouse_documentType: {
      included: (field: string) => `The ${field} is required`,
    },
    dateFrom: {
      date_format: (field: string, [format]: string) => `Preferred format: ${format}`,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      date_between: (field: string, [min, max]: any) => `The date must be earlier than ${max}`,
    },
    dateTo: {
      date_format: (field: string, [format]: string) => `Preferred format: ${format}`,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      date_between: (field: string, [min, max]: any) => `The date must be later than ${min}`,
    },
    moneyUsage: {
      excluded: (field: string) => `The field ${field} is required`,
    },
    personalCode: {
      regex: (field: string) => `${capitalize(field)} field value is invalid`,
    },
    spousePersonalCode: {
      regex: (field: string) => `${capitalize(field)} field value is invalid`,
    },
  },
  messages: {
    _default: (field: string) => `The ${field} value is not valid`,
    // after: (field: string, [target, inclusion]: any) => `The ${field} must be after ${inclusion ? "or equal to " : ""}${target}`,
    after: () => "Incorrect date",
    alpha: (field: string) => `The ${field} field may only contain alphabetic characters`,
    alpha_dash: (field: string) => `The ${field} field may contain alpha-numeric characters as well as dashes and underscores`,
    alpha_num: (field: string) => `The ${field} field may only contain alpha-numeric characters`,
    alpha_spaces: (field: string) => `The ${field} field may only contain alphabetic characters as well as spaces`,
    // before: (field: string, [target, inclusion]: any) => `The ${field} must be before ${inclusion ? "or equal to " : ""}${target}`,
    before: () => "Incorrect date",
    between: (field: string, [min, max]: any) => `The ${field} field must be between ${min} and ${max}`,
    confirmed: (field: string) => `The ${field} confirmation does not match`,
    credit_card: (field: string) => `The ${field} field is invalid`,
    date_between: (field: string, [min, max]: any) => `The ${field} must be between ${min} and ${max}`,
    // date_format: (field: string, [format]: string) => `The ${field} must be in the format ${format}`,
    date_format: () => "No date entered",
    decimal: (field: string, [decimals = "*"] = []) =>
      `The ${field} field must be numeric and may contain${!decimals || decimals === "*" ? "" : " " + decimals} decimal points`,
    digits: (field: string, [length]: string) => `The ${field} field must contain exactly ${length} digits`,
    dimensions: (field: string, [width, height]: any) => `The ${field} field must be ${width} pixels by ${height} pixels`,
    email: (field: string) => `The ${field} field must be a valid email`,
    excluded: (field: string) => `The ${field} field must be a valid value`,
    ext: (field: string) => `The ${field} field must be a valid file`,
    image: (field: string) => `The ${field} field must be an image`,
    included: (field: string) => `The ${field} field must be a valid value`,
    integer: (field: string) => `The ${field} field must be an integer`,
    ip: (field: string) => `The ${field} field must be a valid ip address`,
    ip_or_fqdn: (field: string) => `The ${field} field must be a valid ip address or FQDN`,
    length: (field: string, [length, max]: any) => {
      if (max) {
        return `The ${field} length must be between ${length} and ${max}`;
      }

      return `The ${field} length must be ${length}`;
    },
    max: (field: string, [length]: string) => `The ${field} field may not be greater than ${length} characters`,
    max_value: (field: string, [max]: any) => `The ${field} field must be ${max} or less`,
    mimes: (field: string) => `The ${field} field must have a valid file type`,
    min: (field: string, [length]: string) => `The ${field} field must be at least ${length} characters`,
    min_value: (field: string, [min]: any) => `The ${field} field must be ${min} or more`,
    numeric: (field: string) => `The ${field} field may only contain numeric characters`,
    regex: (field: string) => `The ${field} field format is invalid`,
    required: (field: string) => `The ${field} field is required`,
    required_if: (field: string, [target]: string) => `The ${field} field is required when the ${target} field has this value`,
    size: (field: string, [size]: any) => `The ${field} size must be less than ${size}`,
    url: (field: string) => `The ${field} field is not a valid URL`,
    inList: (field: string) => `Field ${field} is required`,
    isAccepted: () => "Control numbers for the document are incorrect",
  },
};
