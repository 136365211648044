import { UserVerifyRequestParams } from "./userVerifyParams";

class SontClient {
  /**
   * Sends fetch request and returns json transformed from response XML
   *
   * @param soapAction
   * @param body
   *
   * @returns Promise
   */
  // sendRequest(path: string, method: string, bodyParams: any[]): Promise<any> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendRequest(path: string, method: string, body: UserVerifyRequestParams | null, headers?: HeadersInit): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        let parsedBodyParams = {};
        if (body) {
          // body usually comes in nested format because of typing SontRequestParams
          // so we need to use only internal piece of it - the value of the first object in body
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const [key, value] of Object.entries(body)) {
            parsedBodyParams = value;
          }
        }

        const requestOptions: RequestInit = {
          method: method,
          body: JSON.stringify(parsedBodyParams),
          redirect: "follow",
          headers: {
            ...{
              // "Content-Type": "multipart/form-data"
              "Content-Type": "application/json",
            },
            ...headers,
          },
        };

        if (method === "GET" || method === "HEAD") {
          delete requestOptions.body;
        }

        fetch(process.env.VUE_APP_MOLECULER_URL + path, requestOptions)
          .then((response) => {
            return response.json().then((result) => {
              if (response.status !== 200) {
                return { error: response.status, result };
              }

              return result;
            });
          })
          .then((result) => {
            if (result.error) {
              reject(result);

              return;
            }

            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default SontClient;
