import lsInit from "@/lib/localStorageSlimInit";
import SontClient from "@/lib/userVerify/userVerifyClient";
import {
  DeviceVerificationEntryInterface,
  SendSmsResponseInterface,
  userVerifyActions,
  UserVerifyRequestParams,
} from "@/lib/userVerify/userVerifyParams";
import Vue from "vue";
import { mapGetters } from "vuex";
import { SoapOK } from "@/lib/soap/soapParams";
import { GlobalStorageKeys } from "@/interfaces/applicationSettings";
import { SellerAuthentication } from "@/interfaces/seller";

const ls = lsInit();

const userVerifyRequests = Vue.extend({
  computed: {
    ...mapGetters({
      is2FaEnabled: "auth/is2FaEnabled",
      additionalInfo: "auth/additionalInfo",
    }),
  },
  methods: {
    ...mapGetters({
      user: "auth/user",
    }),
    requestVerificationSms(phone: string | number, locale: string) {
      const params: UserVerifyRequestParams = {
        [userVerifyActions.sendSms]: {
          phone: phone.toString(),
          locale: locale,
        },
      };

      return new Promise<SendSmsResponseInterface>((resolve, reject) => {
        new SontClient()
          .sendRequest(userVerifyActions.sendSms, "POST", params)
          .then((res: SendSmsResponseInterface) => resolve(res))
          .catch((error: SendSmsResponseInterface) => reject(error));
      });
    },
    verifyCode(token: string, code: string, metodas: SellerAuthentication | number) {
      const params: UserVerifyRequestParams = {
        [userVerifyActions.verifyCode]: {
          code: code,
          token: token,
          metodas,
        },
      };

      return new Promise<SendSmsResponseInterface>((resolve, reject) => {
        new SontClient()
          .sendRequest(userVerifyActions.verifyCode, "POST", params)
          .then((res: SendSmsResponseInterface) => resolve(res))
          .catch((error: SendSmsResponseInterface) => reject(error));
      });
    },
    verifyContactsCode(token: string, code: string) {
      const params: UserVerifyRequestParams = {
        [userVerifyActions.verifyContactsCode]: {
          code: code,
          token: token,
          metodas: this.additionalInfo.Autentifikacija,
          sesija: this.user().Sesija,
        },
      };

      return new Promise<SendSmsResponseInterface>((resolve, reject) => {
        new SontClient()
          .sendRequest(userVerifyActions.verifyContactsCode, "POST", params)
          .then((res: SendSmsResponseInterface) => resolve(res))
          .catch((error: SendSmsResponseInterface) => reject(error));
      });
    },
    changePassword(token: string, password: string, login?: string) {
      const paramValues: UserVerifyRequestParams[userVerifyActions.changePassword] = {
        token,
        password,
      };

      if (login) {
        paramValues["login"] = login;
      }

      const params: UserVerifyRequestParams = {
        [userVerifyActions.changePassword]: paramValues,
      };

      return new Promise((resolve, reject) => {
        new SontClient()
          .sendRequest(userVerifyActions.changePassword, "POST", params)
          .then((res) => {
            if (res.status && res.info.KlaidosKodas == SoapOK) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((error) => reject(error));
      });
    },
    requestVerificationEmail(email: string) {
      const params: UserVerifyRequestParams = {
        [userVerifyActions.requestVerificationEmail]: {
          email,
        },
      };

      return new Promise((resolve, reject) => {
        new SontClient()
          .sendRequest(userVerifyActions.requestVerificationEmail, "POST", params)
          .then((res) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((error) => reject(error));
      });
    },
    requestContactVerification(vartotojoVardas: string, metodas: SellerAuthentication | number, Emailas: string, MobilusTelefonas: string) {
      const params: UserVerifyRequestParams = {
        [userVerifyActions.contactVerification]: {
          vartotojoVardas,
          metodas,
          Emailas,
          MobilusTelefonas,
          sesija: this.user().Sesija,
        },
      };

      return new Promise((resolve, reject) => {
        new SontClient()
          .sendRequest(userVerifyActions.contactVerification, "POST", params)
          .then((res) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((error) => reject(error));
      });
    },
    requestAccountUpdateCode(vartotojoVardas: string, metodas: SellerAuthentication | number, Emailas: string, MobilusTelefonas: string) {
      const params: UserVerifyRequestParams = {
        [userVerifyActions.accountUpdate]: {
          vartotojoVardas,
          metodas,
          Emailas,
          MobilusTelefonas,
          sesija: this.user().Sesija,
        },
      };

      return new Promise((resolve, reject) => {
        new SontClient()
          .sendRequest(userVerifyActions.accountUpdate, "POST", params)
          .then((res) => {
            if (res.status) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((error) => reject(error));
      });
    },
    accountUpdateVerifyCode(token: string, code: string, metodas?: SellerAuthentication | number) {
      const params: UserVerifyRequestParams = {
        [userVerifyActions.accountUpdateVerifyCode]: {
          code: code,
          token: token,
          metodas: metodas || this.additionalInfo.Autentifikacija,
          sesija: this.user().Sesija,
        },
      };

      return new Promise<SendSmsResponseInterface>((resolve, reject) => {
        new SontClient()
          .sendRequest(userVerifyActions.accountUpdateVerifyCode, "POST", params)
          .then((res: SendSmsResponseInterface) => resolve(res))
          .catch((error: SendSmsResponseInterface) => reject(error));
      });
    },
    requestDeviceCheck(vartotojoVardas: string, metodas: SellerAuthentication = SellerAuthentication.EMAIL, sesija: string) {
      let irenginioId = "";
      const verificationInfo: DeviceVerificationEntryInterface | null = ls.get(
        `${GlobalStorageKeys.DEVICE_VERIFICATION_COOKIE}-${this.user().userName}`
      );
      if (verificationInfo && verificationInfo?.verified && verificationInfo.deviceId?.length) {
        irenginioId = verificationInfo.deviceId;
      }

      const params: UserVerifyRequestParams = {
        [userVerifyActions.deviceCheck]: {
          vartotojoVardas,
          sesija,
          irenginioId,
          metodas,
        },
      };

      return new SontClient().sendRequest(userVerifyActions.deviceCheck, "POST", params);
    },
    requestDeviceCheckTime(vartotojoVardas: string, sesija: string) {
      const params: UserVerifyRequestParams = {
        [userVerifyActions.deviceCheckTime]: {
          vartotojoVardas,
          sesija,
        },
      };

      return new SontClient().sendRequest(userVerifyActions.deviceCheckTime, "POST", params);
    },
    requestRegisterDevice(token: string, code: string) {
      const params: UserVerifyRequestParams = {
        [userVerifyActions.deviceRegister]: {
          sesija: this.user().Sesija,
          token,
          code,
        },
      };

      return new SontClient().sendRequest(userVerifyActions.deviceRegister, "POST", params);
    },
    requestSendingStatus(requestID: string) {
      return new SontClient().sendRequest(userVerifyActions.sendingStatus.replace("{id}", requestID), "GET", null);
    },
  },
});

export default userVerifyRequests;
