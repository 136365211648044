// inspired by https://stackoverflow.com/questions/183485/converting-punycode-with-dash-character-to-unicode and converted to typescript

interface Utf16Interface {
  decode(input: string): number[];
  encode(input: number[]): string;
}

class Punycode {
  private readonly initial_n: number = 0x80;
  private readonly initial_bias: number = 72;
  private readonly delimiter: string = "\x2D";
  private readonly base: number = 36;
  private readonly damp: number = 700;
  private readonly tmin: number = 1;
  private readonly tmax: number = 26;
  private readonly skew: number = 38;
  private readonly maxint: number = 0x7fffffff;

  public utf16: Utf16Interface = {
    decode: (input: string): number[] => {
      const output: number[] = [];
      let i = 0;
      const len = input.length;
      let value: number;
      let extra: number;

      while (i < len) {
        value = input.charCodeAt(i++);
        if ((value & 0xf800) === 0xd800) {
          extra = input.charCodeAt(i++);
          if ((value & 0xfc00) !== 0xd800 || (extra & 0xfc00) !== 0xdc00) {
            throw new RangeError("UTF-16(decode): Illegal UTF-16 sequence");
          }
          value = ((value & 0x3ff) << 10) + (extra & 0x3ff) + 0x10000;
        }
        output.push(value);
      }
      return output;
    },

    encode: (input: number[]): string => {
      const output: string[] = [];
      let i = 0;
      const len = input.length;
      let value: number;

      while (i < len) {
        value = input[i++];
        if ((value & 0xf800) === 0xd800) {
          throw new RangeError("UTF-16(encode): Illegal UTF-16 value");
        }
        if (value > 0xffff) {
          value -= 0x10000;
          output.push(String.fromCharCode(((value >>> 10) & 0x3ff) | 0xd800));
          value = 0xdc00 | (value & 0x3ff);
        }
        output.push(String.fromCharCode(value));
      }
      return output.join("");
    },
  };

  private decode_digit(cp: number): number {
    return cp - 48 < 10 ? cp - 22 : cp - 65 < 26 ? cp - 65 : cp - 97 < 26 ? cp - 97 : this.base;
  }
  private encode_digit(d: number, flag: number): number {
    return d + 22 + 75 * Number(d < 26) - Number(flag !== 0) * 32;
  }

  private adapt(delta: number, numpoints: number, firsttime: boolean): number {
    let k: number;
    delta = firsttime ? Math.floor(delta / this.damp) : delta >> 1;
    delta += Math.floor(delta / numpoints);

    for (k = 0; delta > ((this.base - this.tmin) * this.tmax) >> 1; k += this.base) {
      delta = Math.floor(delta / (this.base - this.tmin));
    }
    return Math.floor(k + ((this.base - this.tmin + 1) * delta) / (delta + this.skew));
  }
  private encode_basic(bcp: number, flag: boolean): number {
    const isLowercase = bcp - 97 < 26;
    const shift = isLowercase ? 1 << 5 : 0;
    const adjusted = bcp - shift;

    const isUppercase = !flag && adjusted - 65 < 26;
    const finalShift = isUppercase ? 1 << 5 : 0;

    return adjusted + finalShift;
  }

  public decode(input: string, preserveCase?: boolean): string {
    const output: number[] = [];
    const case_flags: boolean[] = [];
    const input_length = input.length;

    let n = this.initial_n;
    let i = 0;
    let bias = this.initial_bias;
    let basic = input.lastIndexOf(this.delimiter);

    if (basic < 0) basic = 0;

    for (let j = 0; j < basic; ++j) {
      if (preserveCase) case_flags[output.length] = input.charCodeAt(j) - 65 < 26;
      if (input.charCodeAt(j) >= 0x80) {
        throw new RangeError("Illegal input >= 0x80");
      }
      output.push(input.charCodeAt(j));
    }

    for (let ic = basic > 0 ? basic + 1 : 0; ic < input_length; ) {
      const oldi = i;
      let w = 1;
      for (let k = this.base; ; k += this.base) {
        if (ic >= input_length) {
          throw new RangeError("punycode_bad_input(1)");
        }
        const digit = this.decode_digit(input.charCodeAt(ic++));

        if (digit >= this.base) {
          throw new RangeError("punycode_bad_input(2)");
        }
        if (digit > Math.floor((this.maxint - i) / w)) {
          throw new RangeError("punycode_overflow(1)");
        }
        i += digit * w;
        const t = k <= bias ? this.tmin : k >= bias + this.tmax ? this.tmax : k - bias;
        if (digit < t) break;
        if (w > Math.floor(this.maxint / (this.base - t))) {
          throw new RangeError("punycode_overflow(2)");
        }
        w *= this.base - t;
      }

      const out = output.length + 1;
      bias = this.adapt(i - oldi, out, oldi === 0);

      if (Math.floor(i / out) > this.maxint - n) {
        throw new RangeError("punycode_overflow(3)");
      }
      n += Math.floor(i / out);
      i %= out;

      if (preserveCase) {
        case_flags.splice(i, 0, input.charCodeAt(ic - 1) - 65 < 26);
      }

      output.splice(i, 0, n);
      i++;
    }

    if (preserveCase) {
      for (let i = 0; i < output.length; i++) {
        if (case_flags[i]) {
          output[i] = String.fromCharCode(output[i]).toUpperCase().charCodeAt(0);
        }
      }
    }

    return this.utf16.encode(output);
  }

  public encode(input: string | number[], preserveCase?: boolean): string {
    const case_flags = preserveCase ? this.utf16.decode(input as string) : undefined;
    const inputArray = this.utf16.decode((input as string).toLowerCase());
    const output: string[] = [];

    let n = this.initial_n;
    let delta = 0;
    let bias = this.initial_bias;
    let h = 0;
    let b = 0;

    for (let j = 0; j < inputArray.length; ++j) {
      if (inputArray[j] < 0x80) {
        output.push(
          String.fromCharCode(case_flags ? this.encode_basic(inputArray[j], case_flags[j] as unknown as boolean) : inputArray[j])
        );
        b++;
      }
    }

    h = b;

    if (b > 0) output.push(this.delimiter);

    while (h < inputArray.length) {
      let m = this.maxint;
      for (let j = 0; j < inputArray.length; ++j) {
        if (inputArray[j] >= n && inputArray[j] < m) {
          m = inputArray[j];
        }
      }

      if (m - n > Math.floor((this.maxint - delta) / (h + 1))) {
        throw new RangeError("punycode_overflow(1)");
      }

      delta += (m - n) * (h + 1);
      n = m;

      for (let j = 0; j < inputArray.length; ++j) {
        if (inputArray[j] < n) {
          if (++delta > this.maxint) {
            throw new RangeError("punycode_overflow(2)");
          }
        }

        if (inputArray[j] === n) {
          let q = delta;
          for (let k = this.base; ; k += this.base) {
            const t = k <= bias ? this.tmin : k >= bias + this.tmax ? this.tmax : k - bias;
            if (q < t) break;
            output.push(String.fromCharCode(this.encode_digit(t + ((q - t) % (this.base - t)), 0)));
            q = Math.floor((q - t) / (this.base - t));
          }
          output.push(String.fromCharCode(this.encode_digit(q, preserveCase && case_flags && case_flags[j] ? 1 : 0)));
          bias = this.adapt(delta, h + 1, h === b);
          delta = 0;
          h++;
        }
      }
      delta++;
      n++;
    }

    return output.join("");
  }

  public ToASCII(input: string): string {
    if (input.includes("@")) {
      const [localPart, domain] = input.split("@");

      const asciiDomain = this.convertDomainToASCII(domain);

      return `${localPart}@${asciiDomain}`;
    }

    return this.convertDomainToASCII(input);
  }

  public ToUnicode(input: string): string {
    if (!input) return "";

    if (input.includes("@")) {
      const [localPart, domain] = input.split("@");

      const unicodeDomain = this.convertDomainToUnicode(domain);

      return `${localPart}@${unicodeDomain}`;
    }

    return this.convertDomainToUnicode(input);
  }

  private convertDomainToASCII(domain: string): string {
    const domain_array = domain.split(".");
    return domain_array.map((s) => (s.match(/[^A-Za-z0-9-]/) ? "xn--" + this.encode(s) : s)).join(".");
  }

  private convertDomainToUnicode(domain: string): string {
    const domain_array = domain.split(".");
    return domain_array
      .map((s) => {
        if (s.toLowerCase().startsWith("xn--")) {
          try {
            return this.decode(s.slice(4));
          } catch (e) {
            console.warn(`Failed to decode domain part: ${s}`, e);
            return s;
          }
        }
        return s;
      })
      .join(".");
  }
}

const punycode = new Punycode();

export default punycode;
