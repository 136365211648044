<template>
  <div v-if="!isFF" class="d-flex align-items-center px-3">
    <b-button variant="link" @click="zoomOut" class="small px-1 py-0 mr-1 text-white no-focus-shadow text-decoration-none" size="sm">
      <span class="fs-1">A</span>
    </b-button>
    <b-button variant="link" @click="resetZoom" class="px-1 py-0 mr-1 text-white no-focus-shadow text-decoration-none" size="sm">
      <span class="fs-2">A</span>
    </b-button>
    <b-button variant="link" @click="zoomIn" class="px-1 py-1 mr-0 text-white no-focus-shadow text-decoration-none" size="sm">
      <span class="fs-3">A</span>
    </b-button>
  </div>
</template>
<script lang="ts">
import mixins from "vue-typed-mixins";
import { GlobalStorageKeys } from "@/interfaces/applicationSettings";
import featureDetection from "@/mixins/featureDetection";

export default mixins(featureDetection).extend({
  name: "ZoomUi",
  data() {
    return {
      currentZoom: 1,
      maxZoom: 1.2,
      minZoom: 0.8,
    };
  },
  created() {
    this.currentZoom = this.$ls.get(GlobalStorageKeys.UI_SCALE) || this.currentZoom;

    this.setPageZoom(this.currentZoom);
  },
  methods: {
    zoomIn() {
      this.currentZoom = this.maxZoom > +this.currentZoom + 0.01 ? +this.currentZoom + 0.01 : this.maxZoom;
      this.setPageZoom(this.currentZoom);
    },
    zoomOut() {
      this.currentZoom = +this.currentZoom - 0.01 > this.minZoom ? +this.currentZoom - 0.01 : this.minZoom;
      this.setPageZoom(this.currentZoom);
    },
    resetZoom() {
      this.currentZoom = 1;
      this.setPageZoom(this.currentZoom);
    },
    setPageZoom(value: number) {
      let document = window.document.querySelector("body") as HTMLBodyElement;
      let root = window.document.querySelector(":root") as HTMLBaseElement;
      if (!document || !root) {
        return;
      }

      this.$ls.set(GlobalStorageKeys.UI_SCALE, value);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: unreachable code
      document.style.zoom = value;
      root.style.setProperty("--zoom", value.toString());
    },
  },
});
</script>

<style>
.fs-1 {
  font-size: 12px !important;
  line-height: 16px !important;
}
.fs-2 {
  font-size: 14px !important;
  line-height: 16px !important;
}
.fs-3 {
  font-size: 16px !important;
  line-height: 16px !important;
}
</style>
