<template>
  <div
    :id="`${_uid}-count-down`"
    @click="emitClick"
    class="cursor-pointer"
    :class="{ 'text-danger': leftTime === 0, 'text-muted': leftTime > 0 && !noIcon }"
  >
    <b-icon v-if="!noIcon" :icon="leftTime > 0 ? iconWaiting : iconExpired" :animation="noAnimation ? '' : animation"></b-icon>

    <b-popover v-if="!noIcon" :target="`${_uid}-count-down`" triggers="hover" placement="top">
      <span v-if="tooltipText.length > 0 && leftTime > 0" class="small">{{ tooltipText }}{{ countDownText }}</span>
      <span v-else>{{ sendAgainText }}</span>
    </b-popover>

    <div v-if="noIcon">
      <span v-if="tooltipText.length > 0 && leftTime > 0">{{ tooltipText }}{{ countDownText }}</span>
      <span v-else>{{ sendAgainText }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "CountDown",
  data() {
    return {
      intervalHandler: null as null | any,
      leftTime: 0,
      animation: "",
    };
  },
  props: {
    seconds: {
      type: Number,
      default: 180,
    },
    started: {
      type: Boolean,
      default: true,
    },
    tooltipText: {
      type: String,
      default: "",
    },
    sendAgainText: {
      type: String,
      default: "",
    },
    noAnimation: {
      type: Boolean,
      default: false,
    },
    iconWaiting: {
      type: String,
      default: "clock-history",
    },
    iconExpired: {
      type: String,
      default: "arrow-counterclockwise",
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.initTime();
  },
  methods: {
    initTime() {
      if (this.started) {
        this.startInterval();
      }
      this.leftTime = this.seconds * 1000;
    },
    resetInterval() {
      clearInterval(this.intervalHandler);
      this.leftTime = this.seconds * 1000;
      this.startInterval();
    },
    startInterval() {
      this.animation = "fade";

      this.intervalHandler = setInterval(() => {
        if (this.leftTime <= 0) {
          clearInterval(this.intervalHandler);
          this.animation = "";
          return;
        }

        this.leftTime -= 1000;
      }, 1000);
    },
    emitClick() {
      if (this.leftTime > 0) {
        return;
      }

      this.resetInterval();
      this.$emit("action", this.leftTime);
    },
  },
  computed: {
    countDownText() {
      const minutes = Math.floor((this.leftTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((this.leftTime % (1000 * 60)) / 1000);
      return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    },
  },
  watch: {
    seconds() {
      this.resetInterval();
    },
  },
});
</script>
