import { SontSignStatusInterface, SontTokenInterface, SontUserInterface } from "@/interfaces/sont";
import { Module, ActionContext } from "vuex";

// state
export const state = {
  expires: 0,
  token: {},
  user: {},
  signStatus: {},
};

// getters
export const getters = {
  token: (state: { token: SontTokenInterface }, getters: { isExpired: boolean }) => {
    if (!state.token || getters.isExpired) {
      return {};
    }

    return state.token;
  },
  isExpired: (state: { token: SontTokenInterface; expires: number }): boolean => {
    const timeNow = Math.floor(Date.now() / 1000);
    if (state.token && timeNow < state.expires) {
      return false;
    }

    return true;
  },
  user: (state: { user: SontUserInterface }) => {
    return state.user;
  },
  signStatus: (state: { signStatus: SontSignStatusInterface }) => {
    return state.signStatus;
  },
};

// mutations
export const mutations = {
  setToken(state: { token?: SontTokenInterface }, payload: SontTokenInterface) {
    state.token = payload;
  },
  setExpires(state: { expires: number }, payload: number) {
    state.expires = payload;
  },
  setSontUser(state: { user: SontUserInterface }, payload: SontUserInterface) {
    state.user = payload;
  },
  setSignStatus(state: { signStatus?: SontSignStatusInterface }, payload: SontSignStatusInterface) {
    state.signStatus = payload;
  },
};

// actions
export const actions = {
  resetStore({ commit }: ActionContext<typeof state, any>) {
    commit("setToken", {});
    commit("setSontUser", {});
    commit("setSignStatus", {});
    commit("setExpires", 0);
  },
  storeToken({ commit }: ActionContext<typeof state, any>, payload: SontTokenInterface) {
    commit("setToken", payload || {});

    let expires = 0;
    if (payload && payload.expires_in > 0) {
      expires = Math.floor(Date.now() / 1000) + payload.expires_in;
    }

    commit("setExpires", expires);
  },
  storeSontUser({ commit }: ActionContext<typeof state, any>, payload?: SontUserInterface) {
    commit("setSontUser", payload);
  },
  storeSignStatus({ commit }: ActionContext<typeof state, any>, payload?: SontSignStatusInterface) {
    commit("setSignStatus", payload);
  },
};

const sontModule: Module<any, any> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default sontModule;
