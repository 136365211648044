/* eslint-disable prettier/prettier */
import { LocaleMessageObject } from "vue-i18n";

const translations: Record<string, LocaleMessageObject> = {
  lt: {
    meta: {
      titles: {
        login: "Prisijungimas",
        applications: "Paraiškos ir sutartys",
        news: "Naujienos",
        newApplication: "Nauja paraiška",
        newApplicationStep2: "Nauja paraiška",
        newApplicationStep3: "Nauja paraiška",
        newApplicationStep4: "Nauja paraiška",
        newApplicationStep5: "Nauja paraiška",
        editApplicationStep2: "Paraiška / Sutartis",
        editApplicationStep3: "Paraiška / Sutartis",
        editApplicationStep4: "Paraiška / Sutartis",
        editApplicationStep5: "Paraiška / Sutartis",
        searchApplications: "Paieška",
        accountSettings: "Paskyros valdymas",
        reports: "Ataskaitos",
        spreadsheet: "Skaičiuoklė",
        accounts: "Paskyros",
        accountEdit: "Keisti paskyrą",
        accountCreate: "Sukurti paskyrą",
        recognizer: "Dokumentų atpažinimas",
        debtReport: "Skolų ataskaita",
        pageNotFound: "Puslapis nerastas",
        passwordRemind: "Slaptažodžio priminimas",
        permissionDenied: "Prieiga draudžiama",
        passwordUpdate: "Slaptažodžio atnaujinimas",
        verifyDevice: "Įrenginio patvirtinimas",
        autoLoginLimited: "Greitas paraiškos redagavimas",
        autoLogin: "Greitas redagavimas",
        iframeLogin: "Login",
      },
    },
    login: {
      userName: "vartotojo vardas",
      password: "slaptažodis",
      forgotPassword: "pamiršau slaptažodį",
      login: "prisijungti",
      loginIn: "prisijungimas",
      logOut: "atsijungti",
      remindPassword: "slaptažodžio priminimas",
      remind: "priminti",
      userEmail: "el. paštas",
      userPhone: "telefono numeris",
      forgotPasswordCallHelp: "Arba skambinkite tel.",
      checkEmail: "Ačiū. Pasitikrinkite savo el. paštą!",
      confirmSmsCode: "Įveskite SMS žinute gautą kodą",
      confirmEmailCode: "Įveskite el. paštu gautą kodą",
      verifySmsCode: "Patvirtinti",
      smsCode: "kodas",
      sessionExpires: "{sessionTimeout} buvote neaktyvus, prašome prisijungti iš naujo.",
      changePassword: "slaptažodžio keitimas",
      updatePassword: "pakeisti slaptažodį",
      passwordConfirmation: "pakartokite slaptažodį",
      passwordNotMatch: "Slaptažodžiai nesutampa",
      passwordChanged: "slaptažodis pakeistas!",
      verificationTokenMissing: "vartotojo patvirtinimo kodo klaida",
      verificationLoginMissing: "vartotojo vardo nustatyti nepavyko",
      attempts: " bandymai.",
      contactUs: "Nepavykus prisijungti, prašome kreiptis el. paštu <a href='mailto:komercija@sbl.lt'>komercija@sbl.lt</a> arba tel. nr. <a href='tel:+37037409156'>8-37 40 91 56</a>",
      updatePasswordWarn: "slaptažodžio galiojimo laikas baigėsi. Dėl duomenų saugumo prašome pasikeisti slaptažodį.",
      tryResetLater: "slaptažodžio atkūrimo funkcija galima pasinaudoti tik kartą per 3min. Bandykite vėliau.",
      verifyDevice: "patvirtinkite įrenginį",
      verifyDeviceNote: "jungiatės iš neatpažinto (naujo) įrenginio, todėl turite jį patvirtinti.",
      deviceConfirmTimeout: "Pakartoti kodo siuntimą galėsite po ",
      deviceConfirmRepeat: "Atsiųsti naują kodą",
      dataUpdate: "duomenų atnaujinimas",
      dataUpdateWarning: "prašome patvirtinti kontaktinius duomenis ir pasikeisti slaptažodį",
      saveData: "išsaugoti duomenis",
      dataUpdatedSuccessfully: "duomenys sėkmingai atnaujinti",
      passwordRemindDummyEmail:
        "Jei nurodytais duomenimis užregistruota paskyra, išsiųsime el. laišką su tolesnėmis instrukcijomis.",
      passwordRemindDummySMS:
        "Jei nurodytais duomenimis užregistruota paskyra, SMS žinute išsiųsime patvirtinimo kodą.",
      provideLoginTooltip: "Pamiršus prisijungimo vardą, prašome kreiptis el. paštu <a href='mailto:komercija@sbl.lt'>komercija@sbl.lt</a> arba tel. nr. <a href='tel:+37037409156'>8-37 40 91 56</a>",
    },
    sont: {
      sendingErrorSms: "SMS žinutės išsiųsti nepavyko. Bandykite vėliau.",
      sendingErrorEmail: "El. laiško išsiųsti nepavyko. Bandykite vėliau.",
    },
    soap: {
      undefined: "SOAP sistemos klaida",
      "-404": "Tinklo klaida. Pabandykite vėliau.",
      0: "Viskas gerai",
      1: "Sistemos klaida", // internal WS API error [not a response from PVS]
      5: "Negalima sudaryti sutarties",
      10: "Nulinių pajamų atveju",
      1000: "Neteisingas slaptažodis",
      1001: "Neteisingas prisijungimas",
      1002: "Neteisingas prisijungimas",
      // eslint-disable-next-line quotes
      1004: 'Jūsų paskyra užblokuota. Paskyrą atblokuoti galite naudodamiesi <a href="password-remind">slaptažodžio priminimo funkcija</a> arba atblokuoti Jus gali pagrindinis įmonės vartotojas',
      // eslint-disable-next-line quotes
      1005: 'Jūsų paskyra užblokuota. Paskyrą atblokuoti galite naudodamiesi <a href="password-remind">slaptažodžio priminimo funkcija</a> arba atblokuoti Jus gali pagrindinis įmonės vartotojas',
      1006: "Naujas slaptažodis turi nesutapti su senu.",
      1020: "Neteisinga sesija",
      1021: "Neleidžiamas veiksmas",
      1030: "Naujas klientas, nerastas DB, reikia įvesti vardą ir pavardę",
      1031: "Neteisingas asmens kodas",
      1032: "Šio kliento neaptarnauti",
      1050: "Vartotojas su tokiais duomenimis nerastas",
      1051: "Rastas daugiau negu vienas vartotojas. Prašome kreiptis el. paštu <a href='mailto:komercija@sbl.lt'>komercija@sbl.lt</a> arba tel. nr. <a href='tel:837409156'>8-37 40 91 56</a>",
      1060: "Blogas paraiškos ID",
      1063: "Paraiška nerasta",
      1064: "Negalima ištrinti paraiškos",
      1065: "Iš paraiškos jau sudaryta sutartis",
      1080: "Nerastas vadybininkas",
      1090: "Nėra raktą atitinkančių vartotojų",
      1100: "Neteisinga sutarties būsena",
      1200: "Sutarties atmesti nebegalima",
      1300: "Paraiškos veiksmų žurnalas tuščias",
      1400: "Nurodytomis sąlygomis negalima suskaičiuoti grafiko [tuo pačiu sudaryti sutarties]",
      1500: "Nežinoma ataskaita",
      1600: "Sutarties patvirtinti nepavyko",
      1800: "Nepavyko suskaičiuoti siūlomų variantų",
      1900: "Nerasta jokių įrašų",
      2000: "Sistemos klaida",
      2001: "DB grąžino tuščią atsakymą",
      2002: "PDF generavimo klaida",
      2101: "Nepavyko pakeisti sutarties būsenos atmetant",
      2120: "Klaida išsaugant pardavėją",
      2140: "Pardavėjas neturi priskirtų galiojančių salygų variantų",
      2200: "Dokumentas yra archyve.",
      2401: "Leidimas jau panaikintas anksčiau.",
      2402: "Nebuvo atliktas vertinimas pagal tokį leidimą.",
      2403: "Neturite teisės patvirtinti tokio prašymo.",
      2404: "Toks prašymas jau sukurtas anksčiau.",
      2405: "Nebuvo atliktas kliento vertinimas tokiomis sąlygomis",
      2600: "Nepavyko įkelti failo",
      // custom LPS codes
      900: "Toks vartotojo vardas ar el.paštas jau egzistuoja",
      contractSaved: "Paraiška išsaugota",
      signedAgreementDocumentSuccess: "Sutikimas planšetėje sėkmingai pasirašytas",
      signedContractDocumentSuccess: "Sutartis planšetėje sėkmingai pasirašyta",
      left: "Liko ",
    },
    ecr: {
      signRequestOnEcrFailed: "Nepavyko inicijuoti pasirašymo",
      spouseSignRequestOnEcrFailed: "Nepavyko inicijuoti sutuoktinio pasirašymo",
    },
    applications: {
      title: "paraiškos ir sutartys",
      accountNumber: "sąskaitos nr.",
      additionalAccount: "papildoma sąskaita",
      buyer: "pirkėjas",
      buyerFullName: "pardavėjo vardas ir pavardė",
      buyerName: "pirkėjo vardas",
      buyerPersonalCode: "pirkėjo asmens kodas",
      buyerSurname: "pirkėjo pavardė",
      code: "kodas",
      contractConditionVariant: "sutarties sąlygų variantas",
      paymentDay: "kredito mokėjimo diena",
      contractDate: "sutarties data",
      contractDurationMonths: "sutarties trukmė mėnesiais",
      contractNumber: "sutarties nr.",
      draftNumber: "paraiškos nr.",
      contractType: "sutarties tipas",
      createDate: "sudarymo data",
      creditLimit: "kredito limitas",
      eCreditStatus: "E-credit statusas",
      creditSum: "kredito suma",
      filter: "filtruoti",
      hasIncomeCertificate: "yra pajamų pažyma",
      incomeType: "pajamų tipas",
      lowerWinterFee: "mažesnės įmokos žiemą",
      number: "nr.",
      product: "prekė",
      products: "prekės",
      seller: "pardavėjas",
      shopName: "parduotuvės pavadinimas",
      signatureType: "paraiškos pasirašymo būdas",
      contractSignatureType: "sutarties pasirašymo būdas",
      spouseContractSignatureType: "sutuoktinio paraiškos pasirašymo būdas",
      statusAll: "visi",
      application: "paraiška",
      archived: "archyvas",
      unsignedContract: "nepasirašyta sutartis",
      withDefects: "sutartis su trūkumu",
      signedContract: "sutartis",
      rejectedContract: "atmesta sutartis",
      contract: "sutartis",
      status: "statusas",
      total: "iš viso įrašų",
      changeVisibleFields: "Stulpelių rodymo keitimas",
      fieldSettingsInfo: "* nustatymai iškart išsaugomi jūsų naršyklės atmintyje",
      dateFrom: "data nuo",
      dateTo: "data iki",
      exportXlsx: " Eksportuoti",
      useWideTable: "Plati lentelė",
      sureToRemove: "Ar tikrai norite ištrinti?",
      sellerFullName: "Pardavėjo vardas ir pavardė",
      comment: "komentaras",
      moreFilters: "daugiau filtrų",
      fewerFilters: "mažiau filtrų",
      invalidDateRange: "Data turi būti nuo {dateFrom}",
      openEcreditSession: "Atidaryti e-credit sesiją",
    },
    newApplication: {
      title: "nauja paraiška",
      sellerInfo: "pardavėjo info",
      actionLog: "Veiksmų žurnalas",
      download: "Jei norite atsisiųsti sutartį, atšaukite pasirašymą nuotoliu",
      cancellation: "Sutarties atmesti negalima, kol neatšauktas pasirašymas nuotoliu",
      lastStep: "Paskutinis veiksmas",
      chooseSeller: "rinktis pardavėją",
      next: "toliau",
      back: "atgal",
      companyName: "įmonės pavadinimas",
      seller: "pardavėjas",
      choose: "pasirinkti",
      buyerInfo: "pirkėjo informacija",
      personalCode: "asmens kodas",
      spousePersonalCode: "sutuoktinio asmens kodas",
      creditType: "kredito tipas",
      creditTypeNoSpouse: "neturi sutuoktinio",
      creditTypePersonal: "asmeninis kreditas",
      creditTypeSpouse: "šeimos kreditas",
      creditTypeTooltips: {
        "option-0": "vertinti be sutuoktinio",
        "option-1": "vertinti be sutuoktinio",
        "option-2": "vertinti su sutuoktiniu",
      },
      change: "keisti",
      spouseInformation: "sutuoktinio informacija",
      contractNumber: "sutarties nr.",
      draftNumber: "paraiškos nr.",
      phone: "telefonas",
      phoneNumber: "telefono numeris",
      phoneNumberShort: "tel. nr. ",
      personalCodeShort: "a.k.",
      spousePhoneNumber: "sutuoktinio telefono numeris",
      company: "įmonė",
      update: "atnaujinti",
      applicationDetails: "paraiškos duomenys",
      search: "ieškoti",
      searchDanger: "nėra raktą atitinkančių vartotojų",
      clientProvidedData: "kliento duomenys",
      canIncomeCollapse: "ar pajamos gali sumažėti?",
      catalogWithException: "Taikyti akcijos išimtį",
      yes: "taip",
      no: "ne",
      income: "pajamos",
      clientIncome: "kliento pajamos",
      spouseIncome: "sutuoktinio pajamos",
      incomeType: "pajamų tipas",
      spouseIncomeType: "sutuoktinio pajamų tipas",
      unsignedDocuments: "nepasirašyti dokumentai",
      spouseDataObtainMethod: "sutuoktinio duomenų gavimas",
      fillSpouseData: "pildyti duomenis",
      provideSpouseDataOnline: "duomenis nuotoliu supildys ir pateiks pats sutuoktinis",
      provideSpouseDataInfo: "Nurodytais sutuoktinio kontaktais išsiųsime nuorodą sutikimo pasirašymui.",
      dataObtainMethod: "duomenų gavimas",
      fillData: "pildyti duomenis",
      provideDataOnline: "duomenis nuotoliu supildys ir pateiks pats klientas",
      provideDataInfo: "Nurodytais kontaktais išsiųsime nuorodą sutikimo pasirašymui.",
      printDocuments: "spausdinti",
      emailDocuments: "siųsti el. paštu",
      incomeDeclarationExists: "yra pažyma apie 4 mėn. pajamas",
      workplace: "darbovietė",
      consumerCreditsPerMonth: "vartojimo kreditai, Eur/mėn",
      mortgagesPerMont: "būsto paskolos, Eur/mėn",
      creditUserIsMe: "naudosiu kreditą pats",
      politicalActivity: "politinis aktyvumas",
      marketingAgreements: "rinkodaros sutikimai",
      spouseMarketingAgreements: "sutuoktinio rinkodaros sutikimai",
      directMarketing: "tiesioginė rinkodara",
      partnerOffers: "partnerių pasiūlymai",
      dataProfiling: "duomenų profiliavimas",
      spouseDirectMarketing: "sutuoktinio tiesioginė rinkodara",
      spousePartnerOffers: "sutuoktinio partnerių pasiūlymai",
      spouseDataProfiling: "sutuoktinio duomenų profiliavimas",
      directMarketingTooltip: "Sutikimas SB įmonėms tvarkyti asmens duomenis (vardą, pavardę, telefono numerį, elektroninį paštą, adresą) tiesioginės rinkodaros tikslais siekiant teikti pranešimus ir pasiūlymus apie teikiamas paslaugas, ir teirautis nuomonės apie teikiamų paslaugų bei aptarnavimo kokybę.",
      partnerOffersTooltip: "Sutikimas SB įmonėms tvarkyti asmens duomenis (vardą, pavardę, telefono numerį, elektroninį paštą, adresą) siekiant teikti pasiūlymus dėl atrinktų partnerių prekių ar paslaugų. Aktualius SB įmonių partnerių sąrašus rasite čia <a href=\"https://www.sb.lt/imoniu-partneriu-sarasas\" target=\"_blank\">www.sb.lt/imoniu-partneriu-sarasas</a>",
      dataProfilingTooltip: "Sutikimas SB įmonėms teikti tiesioginės rinkodaros pasiūlymus, juos suasmeninant - tvarkyti (įskaitant profiliuoti) asmens duomenis ir automatiniu būdu atlikti susijusių savybių vertinimą; taip pat dalintis šiais duomenimis su kitomis SB įmonėmis siekiant numatyti poreikius ir pasiūlyti asmeniškai tinkančią paslaugą, produktą.",
      dataProfilingDisabledTooltip: "Netaikoma, nes nesutikta su tiesioginės rinkodaros pasiūlymų gavimu",
      application: "paraiška",
      spouseApplication: "sutuoktinio paraiška",
      print: "spausdinti",
      signOnTablet: "pasirašyti planšetėje",
      signRemotely: "pasirašyti nuotoliu",
      incomeTypeHiredWorker: "samdomas darbuotojas",
      incomeTypePensioner: "pensininkas",
      incomeTypeOther: "kita",
      incomeTypeOfficer: "pareigūnas",
      incomeTypeHiredFarmer: "ūkininkas",
      creditorAccountNumber: "kreditoriaus sąskaitos numeris",
      name: "vardas",
      surname: "pavardė",
      spouseName: "sutuoktinio vardas",
      spouseSurname: "sutuoktinio pavardė",
      email: "el. paštas",
      spouseEmail: "sutuoktinio el. paštas",
      optional: "(neprivaloma)",
      emailTooltip:
        "Nurodytu el. paštu bus siunčiama informacija klientams apie jų asmens duomenų tvarkymą bei vartojimo kredito sutarties ir kitų informacinių dokumentų kopijos.",
      agreementFileName: "sutikimas",
      signedAgreementFileName: "sutikimas-pasirasytas",
      agreementFileNameSpouse: "sutuoktinio-sutikimas",
      signedAgreementFileNameSpouse: "sutuoktinio-sutikimas-pasirasytas",
      incomePlanned: "planuojamos pajamos ateityje",
      spouseIncomePlanned: "planuojamos sutuoktinio pajamos ateityje",
      politicianNameSurname: "politiko vardas, pavardė",
      politicalInstitution: "politinė institucija",
      politicianConnection: "politinėje veikloje dalyvauja",
      politicianMyself: "pats",
      politicianFamilyMember: "šeima",
      politicianColleague: "kolega",
      documentsForPrinting: "dokumentai",
      creditData: "kredito duomenys",
      financialLiabilities: "finansiniai įsipareigojimai",
      personalData: "asmeniniai duomenys",
      goods: "prekės",
      goodsMissing: "nepridėta prekių arba prekių suma lygi 0",
      addEditGoods: "Pridėti / Redaguoti prekę",
      additionalData: "papildomi duomenys",
      contractConfirmation: "sutarties patvirtinimas",
      uploadDocument: "įkelti dokumentą",
      contractProject: "sutarties projektas",
      printInsuranceAgreement: "Spausdinti sutikimą",
      printInsuranceAgreementPopover: "Atsakykite į visus klausimus",
      printInsuranceAgreementNotGrantedPopover: "Nėra reikiamų sutikimų",
      sendClientDocuments: "siųsti klientui dok",
      showMaxPosibleCredit: "parodyti didžiausią galimą kreditą",
      saveData: "išsaugoti duomenis",
      saveApplication: "išsaugoti paraišką",
      commonInformation: "standartinė informacija",
      initiateContract: "sudaryti sutartį",
      abortContract: "atmesti sutartį",
      documentPackage: "dokumentų paketas",
      agreement: "sutikimas",
      agreementSpouse: "sutuoktinio sutikimas",
      contract: "sutartis",
      contractSpouse: "sutuoktinio sutartis",
      evaluationReport: "vertinimo ataskaita",
      debtReport: "skolų ataskaita",
      debtReportSpouse: "sutuoktinio skolų ataskaita",
      sign: {
        UNKNOWN_SIGN: "-",
        PAPER_SIGN: "popierinė",
        CENT_SIGN: "pavedimu",
        E_SIGN: "el. parašu",
        TABLET_SIGN: "planšetėje",
        INI_SIGN: "inicijuotas el. pasirašymas",
        INI_TABLET_SIGN: "inicijuotas el. pasirašymas planšete",
      },
      actionLogFields: {
        dateEndTime: "Data ir Laikas",
        user: "Vartotojas",
        final: "Veiksmas",
      },
      ecreditStatus: {
        normal: "normali eiga",
        signedContract: "pasirašyta sutartis",
        signedApplication: "pasirašyta paraiška",
        reviewedByManager: "peržiūri vadybininkas",
        terminated: "nutraukta",
        none: "nėra",
      },
      attachFiles: "dokumentų prisegimas",
      chooseFile: "pasirinkite",
      dropFiles: "nutempkite failus čia",
      or: "arba",
      useTabletCamera: "fotografuoti planšetėje",
      fileUploadDisclaimer: "įsitikinkite, jog įkelta nuotrauka nėra susiliejusi, o dokumento duomenys aiškiai įskaitomi",
      exceededFileLimitError: "vienu metu galima įkelti ne daugiau {0} failų",
      exceededFileLimitWarning: "pasiektas failų limitas: vienu metu galima įkelti ne daugiau {0} failų",
      warningAboutSelectedFile: "kai kurie dokumentai nebuvo pridėti. Palaikomi failų tipai: {0}. Maksimalus failo dydis {1} Mb",
      failedFileDisplay: "failo peržiūra negalima",
      selectDocumentType: "pasirinkite tipą",
      documentTypeNotSelected: "nepasirinktas dokumento tipas",
      save: "išsaugoti",
      documentType: "dokumento tipas",
      sum: "suma",
      advancePayment: "avansas",
      ownFunds: "nuosavos lėšos",
      ownFundsTooltip: "Tiesiogiai pardavėjui mokama avanso suma",
      advancePaymentTooltip: "Į lizingo sąskaitą mokama avanso suma",
      invalidValue: "netinkama reikšmė",
      transferToClient: "pervesti klientui",
      duration: "trukmė",
      paymentDay: "mokėjimo diena",
      smallWinterPayments: "mažesnės įmokos žiemą",
      maxCreditPossibilities: "didžiausias galimas kreditas",
      searchingForProposedOptions: "ieškome variantų",
      monthlyPayment: "mėnesinė įmoka",
      maxCreditSum: "maksimali kredito suma",
      paymentSchedule: "mokėjimų grafikas",
      monthShort: "mėn.",
      creditInterest: "palūkanos",
      contractFee: "sutarties mokestis",
      administrationFee: "administravimo mokestis",
      totalCreditAmountValue: "Įmokų suma",
      conditionsOption: "sąlygų variantas",
      accountNumber: "sąskaitos Nr.",
      moneyUsage: "kredito paskirtis",
      dropDownChoose: "pasirinkite",
      source: "šaltinis",
      typeOfContract: "kredito rūšis",
      dateOfContract: "sutarties data",
      totalOutstandingAmount: "likutis su palūk.",
      limitAmount: "kredito limitas",
      outstandingInstalmentCount: "liko įmokų",
      instalmentCount: "įmokų skaičius",
      installmentAmount: "įmoka",
      refinancePossible: "ref",
      installmentAmountCorrected: "patikslinta įmoka",
      overdueDays: "vėlav. d.",
      purpose: "tikslas",
      contractType: {
        mortgage: "būsto",
        creditCards: "kortelė",
        leasing: "lizingas",
        consumer: "vartojimo",
        other: "kita",
      },
      creditor: "kreditorius",
      refinanceAmount: "refin. suma",
      payerCode: "mokėtojo kodas",
      emptyFinancialLiabilities: "Nėra duomenų apie finansinius įsipareigojimus",
      total: "iš viso",
      totalInRegistries: "registruose",
      totalByClient: "pateikta kliento",
      totalEstimation: "vertinama įmoka",
      totalEstate: "būsto kredito įmokos",
      totalConsumer: "vartojimo kredito įmokos",
      goodTitle: "prekė",
      goodGroup: "grupė",
      goodSerialNo: "Prekės / Serijos Nr.",
      goodPrice: "kaina",
      goodQuantity: "kiekis",
      extendedWarranty: "pratęsta garantija",
      goodAmount: "suma",
      insured: "taip",
      noInsurance: "ne",
      goodsTotalPrice: "prekių suma",
      goodsTotalWarranty: "prekių pratęstos garantijos suma",
      city: "miestas",
      street: "gatvė",
      house: "namas",
      flat: "butas",
      postalCode: "pašto indeksas",
      isAddressUsageDifferent: "Gyvenamosios vietos adresas skiriasi nuo korespondencijos",
      documentValidUntil: "galioja iki",
      documentValidUntilValidation: "Reikšmė turi būti tarp {from} ir {to}",
      documentNumber: "dokumento numeris",
      spousePersonalData: "sutuoktinio asmeniniai duomenys",
      personalAddressTitle: "korespondencijos adresas",
      personalDocumentTitle: "dokumentas",
      documentControlLine: "kontrolinė eilutė",
      citizenship: "pilietybė",
      documentValidationNumber: "kontrolinė eilutė",
      additionalAccount: "papildoma sąskaita",
      additionalCode: "papildomas kodas / pastaba",
      contractSigning: "sutarties pasirašymas",
      applicationSigning: "paraiškos pasirašymas",
      contractComment: "komentaras",
      creditCardData: "kortelės duomenys",
      creditCardOwner: "kredito kortelės savininkas",
      cardHolder: "kortelės savininkas",
      pickupAddress: "atsiėmimo vieta",
      reviewChatHistory: "peržiūrėti pokalbių istoriją",
      signOnTab: "pasirašyti planšetėje",
      signOnTabCancel: "atšaukti pasirašymą",
      contractSigned: "sutartis pasirašyta",
      electronicSigning: "el. pasirašymas",
      cancelElectronicSigning: "atšaukti pasirašymą nuotoliu",
      standardInformationFileName: "standartine-informacija",
      pay: "apmokėti",
      contractApprove: "patvirtinti sutartį",
      contractDiscard: "atmesti sutartį",
      confirmContractCancellation: "Sutarties atmetimas",
      sureToCancelContract: "Atmesti sutartį Nr {contract} ?",
      abortCancellation: "atšaukti",
      contractConditionsCompletion: "sutarties atitikimas kriterijams",
      errorAmountTooLarge: "Suma didesnė už prekių sumą",
      failedToLoad: "paraiška nerasta",
      controlLineNumbersInvalid: "įvestos neteisingos kontrolinės eilutės reikšmės",
      scanDocument: "skenuoti dokumentą",
      scanProductsQr: "skenuoti prekę",
      readDeviceCamera: "naudokite esamo įrenginio kamerą",
      readUploadedImage: "įkelkite dokumento nuotrauką",
      readViaQR: "Naudokite kito įrenginio kamerą",
      problemWithQRScan: "Nuskaityti dokumento nepavyko",
      readQRInstructions: "prašome atidaryti mainų nuorodą išmaniajame įrenginyje su QR skaitytuvu",
      incorrectFyleType: "Failo tipas neatpažįstamas, tinka tik JPEG/JPG, PNG arba PDF",
      combinedIncomeValidationError: "Šeimos pajamų suma turi būti didesnė už 0",
      incorrectAdvanceValue: "Netinkama avanso reikšmė",
      sbInsuranceValue: "SB Draudimo Įmoka",
      chooseValidCondition: "Pasirinkite galiojantį sąlygų variantą",
      chooseValidConditionByGoods: "Pasirinkite galimą sąlygų variantą",
      pickAmountOrCondition: "Pasirinkite kitą sumą arba salygų variantą",
      contractInitiationAllowed: "Leista sudaryti sutartį!",
      contractMade: "Sutartis sudaryta!",
      contractApproved: "Sutartis patvirtinta!",
      contentNotFound: "Puslapis nerastas",
      permissionDenied: "Neturite teisių šio puslapio peržiūrai",
      fileUpload: {
        personalDocCopy: "Asmens dokumento kopija",
        incomeDeclaration: "Pažyma apie pajamas",
        idFrontSide: "ID kortelės priekis",
        idBackSide: "ID kortelės galas",
        passport: "Pasas",
        spouseIdFrontSide: "Sutuoktinio ID kortelės priekis",
        spouseIdBackSide: "Sutuoktinio ID kortelės galas",
        spousePassport: "Sutuoktinio pasas",
        driverLicenseFront: "Vairuotojo pažymėjimo priekis",
        driverLicenseBack: "Vairuotojo pažymėjimo galas",
        spouseDriverLicenseFront: "Sutuoktinio vairuotojo pažymėjimo priekis",
        spouseDriverLicenseBack: "Sutuoktinio vairuotojo pažymėjimo galas",
        sbInsuranceDoc: "SB draudimo dok.",
        refinanceDoc: "Refinansavimo dok.",
        otherDoc: "Kita",
        residencePermitFront: "Leidimo nuolat g. Lietuvoje priekis",
        residencePermitBack: "Leidimo nuolat g. Lietuvoje galas",
        spouseResidencePermitFront: "Sutuoktinio leidimo nuolat g. Lietuvoje priekis",
        spouseResidencePermitBack: "Sutuoktinio leidimo nuolat g. Lietuvoje galas",
        residencePermitTemporaryFront: "Leidimo laikinai g. Lietuvoje priekis",
        residencePermitTemporaryBack: "Leidimo laikinai g. Lietuvoje galas",
        spouseResidencePermitTemporaryFront: "Sutuoktinio leidimo laikinai g. Lietuvoje priekis",
        spouseResidencePermitTemporaryBack: "Sutuoktinio leidimo laikinai g. Lietuvoje galas",
        electronicResidencePermitCheckPhoto: "E-leidimo tikrinimo nuotrauka",
        spouseElectronicResidencePermitCheckPhoto: "Sutuoktinio e-leidimo tikrinimo nuotrauka",
        decisionApprovalDocument: "Sprendimo patvirtinimo dok.",
        bankAccountReport: "Banko išrašas",
        spouseBankAccountReport: "Sutuoktinio banko išrašas",
        applicationCopy: "Paraiškos kopija",
        applicationMarketingAgreementCopy: "Paraiškos rinkodaros lapo kopija",
        spouseApplicationCopy: "Sutuoktinio paraiškos kopija",
        spouseApplicationMarketingAgreementCopy: "Sutuoktinio paraiškos rinkodaros lapo kopija",
        barCodeNotExtracted: "neatpažintas brūkšninis kodas",
        errorExctractingBarCode: {
          30: "Klaida įkeliant paraiškos kopiją",
          31: "Klaida įkeliant sutuoktinio paraiškos kopiją",
          32: "Klaida įkeliant paraiškos rinkodaros lapo kopiją",
          33: "Klaida įkeliant sutuoktinio paraiškos rinkodaros lapo kopiją",
        }
      },
      documentUploads: {
        needToUploadDocuments: "Įkelkite pasirinktų tipų dokumentus į sistemą ir tada pakartokite pasirašymo planšete veiksmą",
        uploadPersonalDocument: "Įkelkite asmens dokumento kopiją",
        uploadPersonalDocumentSpouse: "Įkelkite sutuoktinio asmens dokumento kopiją",
        uploadPassport: "Įkelkite paso dokumento kopiją",
        uploadPassportSpouse: "Įkelkite sutuoktinio paso dokumento kopiją",
        uploadResidencePermit: "Įkelkite gyvenamosios vietos dokumento kopiją",
        uploadResidencePermitSpouse: "Įkelkite sutuoktinio gyvenamosios vietos dokumento kopiją",
        documentUploadedSuccessfully: "Dokumentas įkeltas",
        useGlobalDocumentType: "Taikyti visiems įkeliamiems failams",
        provideDocumentCopiesForTheseFiles: "Įkelkite šių dokumentų kopijas",
      },
      fileUploaded: "dokumentas įkeltas",
      proposalsAdditionalInfo: "Su paraiška susiję pranešimai",
      cantChangePersonalCode: "asmens kodo keisti nebegalima",
      invalidPersonalCode: "laukelio asmens kodas reikšmė neteisinga",
      invalidSpousePersonalCode: "laukelio sutuoktinio asmens kodas reikšmė neteisinga",
      signOnTabletBeforeContinuing: "pasirašykite sutikimą (us) planšetėje ir tęskite sutarties pasirašymą",
      sbInsurancePayment: "SB Draudimo duomenys",
      insuranceQuestions: {
        one: "Klientas sutinka, kad siekdamas būti įtrauktas į tarp Kredito davėjo ir Draudiko sudarytą Draudimo sutartį, Kredito davėjas ir Draudikas teisės aktų nustatyta tvarka tvarkytų jo sveikatos duomenis, nurodytus sveikatos deklaracijoje, apdraustojo ilgojoje apklausos anketoje bei kituose Kredito davėjui ar Draudikui pateiktuose ar jo gautuose dokumentuose, įtraukimo į Draudimo sutartį (įskaitant draudimo rizikos įvertinimą, draudimo įmokos dydžio apskaičiavimą) ir vykdymo (įskaitant draudžiamųjų ar nedraudžiamųjų įvykių administravimą) tikslais.",
        two: "Klientas sutinka, kad Draudikas teiktų jam identifikacinius ir kontaktinius duomenis, taip pat sveikatos duomenis (įskaitant informaciją apie sveikatos būklę, diagnozes, prognozes, tyrimo rezultatus, taikytą gydymą) sveikatos priežiūros ir gydymo įstaigoms, teisėsaugos įstaigoms ir institucijoms, kitoms įmonėms, įstaigoms ir organizacijoms (įskaitant sveikatos duomenų teikimą perdraudimo įmonėms, Draudiko pasirinktiems paslaugų teikėjams) ir iš jų gautų kliento identifikacinius ir kontaktinius duomenis, taip pat sveikatos duomenis (įskaitant informaciją apie sveikatos būklę, diagnozes, prognozes, tyrimo rezultatus, taikytą gydymą) tiek, kiek tai reikalinga kliento įtraukimui į draudimo sutartį (įskaitant draudimo sutarties sąlygų suderinimą, draudimo rizikos įvertinimą, draudimo įmokos dydžio apskaičiavimą) ir vykdymui (įskaitant draudžiamųjų ar nedraudžiamųjų įvykių administravimą, draudimo išmokos dydžio nustatymą). Įgyvendinant šiuos tikslus Draudikas turi teisę tikrinti/rinkti/gauti kliento asmens duomenis iš valstybinių registrų.",
        three: "Ar klientas šiuo metu turi kokių nors sveikatos sutrikimų ar simptomų, dėl kurių gydymo kol kas nesikreipė? Gal planuoja apsilankyti pas gydytoją arba yra numatytas ištyrimas dėl sveikatos sutrikimų (išskyrus nežymų peršalimą, gripą ar kitus nežymius virusinius susirgimus)?",
        four: "Ar per paskutinius 2 metus klientas pastoviai vartojo / vartoja medikamentus, ar buvo rekomenduotas / skirtas medikamentinis gydymas (išskyrus vitaminus, maisto papildus ir atvejus, kai buvo gydomas nežymus peršalimas ar gripas, galvos skausmas dėl nervinės įtampos)?",
        five: "Ar per paskutinius 10 metų klientas serga / sirgo, jam buvo diagnozuoti širdies ir kraujagyslių, onkologiniai susirgimai, diabetas, epilepsija ar kitos nervų sistemos ligos, plaučių ligos, psichikos elgesio sutrikimai, inkstų ar su jais susijusios ligos, hepatitas B, C, tuberkuliozė, ŽIV ir kt.?",
        six: "Ar klientui šiuo metu yra taikomas gydymas dėl fizinių ir psichinių negalių arba chroniškų ligų arba bet kokio pobūdžio susirgimų/sužeidimų?",
        seven: "Ar klientui yra numatyta operacija ar gydymas sveikatos priežiūros ar gydymo įstaigoje?",
        eight: "Ar klientui yra gydytojo paskirti tyrimai arba yra atlikta tyrimų, kurių rezultatai dar nežinomi?",
        nine: "Ar klientui buvo diagnozuota: širdies arba plaučių ligos/nepakankamumas, diabetas, epilepsija ar kitokio pobūdžio nualpimai ar traukuliai, nervinio pobūdžio sutrikimai, inkstų ar su jais susijusios ligos, aukštas arba žemas kraujo spaudimas, onkologiniai susirgimai, ŽIV, AIDS, kito pobūdžio negalios?",
      },
      healthDataAgreement: "Sutikimai dėl sveikatos duomenų tvarkymo",
      healthDataDetails: "Sveikatos klausimai",
      contractRequest: "prašymai sudaryti sutartį",
      contractRequests: {
        time: "laikas",
        sum: "suma",
        advancePayment: "avansas",
        duration: "trukmė",
        conditionVariant: "sąlygų variantas",
        creditType: "tipas",
        status: "būsena",
        initiator: "inicijavo",
        closer: "užbaigė",
        note: "komentaras",
        details: "Detaliau",
      },
      newContractRequest: "prašyti leidimo",
      reject: "Atmesti",
      confirm: "Patvirtinti",
      leaveComment: "Pakomentuokite",
      spouseNoteUnknownSign: "Paspaudus mygtuką \"Toliau\", išsiųsime nuorodą paraiškos pasirašymui.",
      spouseNoteIniSign: "Laukiama paraiškos pasirašymo",
      noteUnknownSign: "Paspaudus mygtuką \"Toliau\", išsiųsime nuorodą paraiškos pasirašymui.",
      noteIniSign: "Laukiama paraiškos pasirašymo",
      insuranceDeposit: "Taikyti SBD perskaičiuotą koeficientą",
      insuranceDepositRecalculated: "SBD eksperto perskaičiuotas koeficientas",
      insuranceDepositTooltip: "Pasirinkite \"Taip\" tik tokiu atveju, kai buvo užklausta ir gauta informacija iš SBD ekspertų su perskaičiuotu draudimo koeficientu (priklausomai nuo Kliento pageidaujamo kredito sumos ir trukmės).",
      insuranceDepositAdjustsExpert: "SB draudimo eksperto patikslinta įmoka",
      noValidationIssues: "Formoje nėra klaidų",
      validationIssuesExists: "Formoje yra klaidų",
      invalidValueInField: "Laukelio {field} reikšmė netinkama",
      repeatElSign: "Pakartoti nuorodos siuntimą",
      repeatElSignSpouse: "Pakartoti nuorodos siuntimą sutuoktiniui",
      generateBankReport: "Banko išrašo generavimas",
      generateBankReportSpouse: "Sutuoktinio banko išrašo generavimas",
      generateBankReportButton: {
        "0": "Siųsti nuorodą",
        "1": "Laukiama",
        "2": "Išrašas gautas",
        "3": "Kartoti",
      },
      generateBankReportButtonTooltip: {
        "0": "Užsakyti kliento banko išrašą, kad būtų galima įvertinti kitas kliento gaunamas pajamas",
        "1": "Nuoroda išsiųsta klientui",
        "2": "Kreiptis į SB Lizingo vadybininką dėl papildomų pajamų pagal banko išrašą vertinimo",
        "3": "Išrašo sugeneruoti nepavyko, reikia kartoti išrašo siuntimą",
      },
      signaturesMissing: "Paraišką reikia pasirašyti. Paraišką spausdinkite arba inicijuokite pasirašymą planšetėje",
      spouseSignaturesMissing: "Sutuoktinio paraišką reikia pasirašyti. Paraišką spausdinkite arba inicijuokite pasirašymą planšetėje",
      popoverDocumentPack: "Atsisiųsti dokumentų paketą",
      popoverSignOnTab: "Inicijuoti sutarties pasirašymą planšetėje",
      popoverSignRemotely: "Išsiųsti nuorodą sutarties pasirašymui nuotoliniu būdu",
      popoverRepeatSignRemotely: "Pakartotinai išsiųsti nuorodą sutarties pasirašymui nuotoliniu būdu",
      popoverContractSigned: "Patvirtinti sutarties pasirašymą",
      contractSignedConfirmation: "Spausdami mygtuką žemiau patvirtinate, kad sutartis pasirašyta popieriuje ir visi parašai reikiamose vietose."
    },
    news: {
      title: "Naujienos",
      hello: "Sveiki",
    },
    searchApplications: {
      title: "ieškoti",
      error: "įveskite paraiškos numerį",
    },
    accountSettings: {
      title: "nustatymai",
      accountDetails: "paskyros informacija",
      submitChanges: "keisti duomenis",
      changePassword: "keisti slaptažodį",
      passwordChanging: "slaptažodžio keitimas",
      passwordChanged: "slaptažodis pakeistas",
      createAccount: "Sukurti paskyrą",
      name: "vardas",
      surname: "pavardė",
      email: "el. paštas",
      spouseEmail: "sutuoktinio el. paštas",
      newPassword: "naujas slaptažodis",
      passwordConfirmation: "pakartoti slaptažodį",
      saved: "informacija atnaujinta",
      phone: "mobilus telefonas",
      authenticationMethod: "autentifikacija",
      authenticationByEmail: "el. paštu",
      authenticationBySMS: "SMS žinute",
      authenticationUndecided: "nepasirinkta",
      userName: "Vartotojo vardas",
      shop: "Parduotuvė",
      notePersonalInfo: "dėl pakeitimo kreiptis į komercija@sbl.lt",
      verifyEmail: "įveskite el. paštu gautą kodą",
      verifySms: "įveskite SMS žinute gautą kodą",
      verifyContacts: "patvirtinkite autentifikacijos priemonę",
      enter: "įvesti",
      sendingErrorSms: "patvirtinimo kodo SMS žinute išsiųsti nepavyko. Bandykite vėliau.",
      sendingErrorEmail: "patvirtinimo kodo el. paštu išsiųsti nepavyko. Bandykite vėliau.",
      tooMuchChanges: "Pakeisti telefono ir el. pašto. tuo pačiu metu negalite",
      codeWasSent: "Kodas buvo nusiųstas. Norėdami gauti naują kodą turite palaukti kol kodo galiojimas pasibaigs.",
      sharedAccountNote: "Dėmesio! Keičiami bendro parduotuvės vartotojo nustatymai, kurie gali turėti įtakos visiems jo vardu besijungiantiems vartotojams."
    },
    reports: {
      title: "paraiškos",
      preview: "peržiūrėti ataskaitą",
      download: "atsisiųsti ataskaitą",
      noAvailableReports: "Nėra ataskaitų, kurias būtų galima peržiūrėti."
    },
    spreadsheet: {
      title: "skaičiuoklė",
      conditions: "sąlygos",
      extendedForm: "išplėstinė užklausa",
      shortForm: "trumpa užklausa",
      duration: "trukmė, mėn.",
      amount: "suma, Eur",
      calculate: "skaičiuoti",
      reset: "iš naujo",
      results: "skaičiavimo rezultatai",
      purpose: "paskolos tikslas",
      familyMembersCount: "šeimos narių skaičius",
      netIncome: "pajamos, atskaičius visus mokesčius, Eur/mėn",
      spouseNetIncome: "sutuoktinio pajamos, atskaičius mokesčius, Eur/mėn",
      monthlyCreditPayments: "vartojimo kreditų įmokos, Eur/mėn",
      mortgagePayments: "būsto paskolų įmokos, Eur/mėn",
      birthDate: "gimimo data",
      showMaxCredit: "rodyti didžiausią galimą kreditą",
      createApplication: "sukurti paraišką",
      monthlyPayment: "mėnesinė įmoka",
      sumOfPayments: "įmokų suma",
      interestRate: "palūkanos",
      bkkmn: "BKKMN",
      contractFee: "sutarties mokestis",
      administrationFee: "administravimo mokestis",
      totalCreditAmount: "kredito suma",
      term: "mokėjimo terminas",
      period: "periodas",
      unpaidCredit: "likusi negrąžinta suma",
      creditPayment: "grąžinama kredito suma",
      interestPayment: "palūkanos",
      totalContribution: "įmoka",
      secondCredit: "antras kreditas",
      firstCreditPayments: "pirmojo kredito įmokos",
      paymentScheduleFileName: "mokėjimo-grafikas",
      incompleteListDisplayed: "pastaba! Rodomas nepilnas sąrašas",
    },
    iframeLogin: {
      formTitle: "Verslo partneriams",
      passwordRemindLink: "Slaptažodžio priminimas"
    },
    footer: {
      // eslint-disable-next-line quotes
      rightsReserved: '© UAB "SB lizingas". Visos teisės saugomos.',
    },
    header: {
      updateNote: "Mes atsinaujinome. Senąją versiją galite pasiekti adresu",
    },
    goodsModal: {
      heading: "pakeisti prekę",
      goods: "prekės",
      good: "prekė",
      serialNo: "Prekės / Serijos nr.",
      group: "prekės grupė",
      price: "kaina",
      quantity: "kiekis",
      addProduct: "pridėti prekę",
      scanCode: "skenuoti",
      save: "išsaugoti",
      purchaseInsurance: "Pirkinių draudimas",
      warrantyDuration: "Gamintojo garantija",
      warrantyExtension: "Pratęsta garantija",
      year: "m",
      warrantyExtensionSelected: "Pasirinkus pratęstą garantiją, tokia prekės grupė negalima",
      priceBetweenError: "Prekės kaina turi būti nuo {min} iki {max}",
      serialNoRequired: "Pasirinkus pratęstą garantiją, privaloma įvesti",
      quantityCantBeChanged: "Pasirinkus pratęstą garantiją, negalima keisti",
      extendedWarrantyRequired: "Privaloma pasirinkti pratęstos garantijos trukmę",
      emptyCatalogSerialNoRequired: "Laukelis prekės nr. privalomas",
      minSerialNoLength: "Prekės nr. ilgis turi būti min. {min} simboliai",
      continue: "Tęsti",
      managerInfo: "Vadybininko rolės vartotojui prekių sąrašo įprastai koreguoti nereikia. Pakeitimus būtina suderinti su pardavėju.",
    },
    serviceWorker: {
      updated: "Sistema atsinaujino",
      reloadMessage: "Sistemos atnaujinimai paruošti. Pakeitimai įsigalios atsidarius naują puslapį arba perkrovus esamą.",
      reload: "Perkrauti dabar",
    },
    accounts: {
      list: {
        title: "Paskyros",
        name: "Vardas, Pavardė",
        username: "Prisijungimo vardas",
        email: "El. pašto adresas",
        role: "Rolė",
        active: "Aktyvus",
        store: "Parduotuvė",
        lastLogin: "Paskutinis prisijungimas",
        yes: "Taip",
        no: "Ne",
        show: "Rodyti",
        records: "įrašus",
        search: "Ieškoti",
        edit: "Keisti",
        create: "Sukurti paskyrą",
        deactivate: "Sustabdyti",
        confirmDeactivate: "Ar tikrai?",
        actions: "Veiksmai",
        unblock: "atblokuoti",
        unblockedSuccessfully: "vartotojas sėkmingai atblokuotas",
      },
      edit: {
        title: "Keisti paskyrą",
        loadFailed: "Vartotojas su tokiais duomenimis nerastas",
        // eslint-disable-next-line prettier/prettier
        passwordError: "Slaptažodyje būtinai turi būti naudojama: didžiosios raidės; mažosios raidės; specialūs simboliai (~!@#$%^&*()_+[]\\;’,./{}|:\"<>?);",
      },
      create: {
        title: "Sukurti paskyrą",
      },
      warrant: {
        warn: "Dėmesio! Patvirtinkite darbuotojų įgaliojimus ",
        warn2: "lange. Nepatvirtinus iki ",
        warn3: " vartotojai taps neaktyvūs",
        confirmMessage1: "Ar patvirtinti įgaliotus asmenis esančius lentelėje sudarinėti vartojimo kreditus?",
        confirm: "Tvirtinti",
        // eslint-disable-next-line quotes
        confirmMessage2: 'Įgaliotus asmenis, kurie jau nebedirba įmonėje, galite pašalinti paspausdami prie darbuotojo "Sustabdyti"',
        accounts: "Paskyros",
      },
    },
    roles: {
      0: "Pardavėjas",
      1: "Manager",
      3: "Vadovas",
    },
    contractTypes: {
      goods: "Kredito prekių sutartis",
      3: "Kredito prekių sutartis",
      creditAccount: "Kredito sąskaitos sutartis",
      5: "Kredito sąskaitos sutartis",
      creditCard: "Kredito kortelės sutartis",
      6: "Kredito kortelės sutartis",
      credit: "Internetinė sutartis",
      7: "Internetinė sutartis",
    },
    mazosImokos: {
      0: "Ne",
      1: "Taip",
    },
    pajamuPazyma: {
      0: "Ne",
      1: "Taip",
    },
    tableInfo: {
      totalRecordsDisplayed: "Rodomi įrašai nuo {startRecord} iki {endRecord} iš {totalRecords} įrašų",
      noPostsFound: "Įrašų nerasta",
      totalResults: " (atrinkta iš {totalResults} įrašų)",
      noteAboutResults: "Jeigu norite matyti kitus įrašus, susiaurinkite paieškos kriterijus.",
    },
    microblink: {
      pointDocument: "Nukreipkite kamerą į priekinę dokumento pusę.",
      pointQr: "Nukreipkite kamerą į brūkšninį kodą.",
      documentDetected: "Dokumentas aptiktas",
      qrRecognized: "Prekės brūkšninis kodas nuskenuotas",
      documentNotDetected: "Aptikti nepavyko",
      recognitionFailed: "Dokumento atpažinti nepavyko, bandykite dar kartą",
      qrFailed: "Prekės brūkšninio kodo nuskenuoti nepavyko, bandykite dar kartą",
      scanning: "Skenuojama...",
      rotateDocument: "Pakreipkite dokumentą",
      flipDocument: "Apverskite dokumentą",
      zoomIn: "Priartinkite dokumentą",
      zoomOut: "Atitraukite dokumentą",
      unhandledStatus: "Nenustatyta nuskaitymo būsena!",
      provideSpouseDocument: "Skenuokite sutuoktinio dokumentą arba įveskite asmens kodą",
      documentRecognized: "Dokumentas nuskaitytas",
      licenseError: "Šiuo metu dokumentų atpažinimo ir prekių skenavimo paslauga negalima. Prašome pabandyti vėliau.",
      qrCodeScanned: "QR kodas nuskaitytas, laukiame duomenų...",
      unableToUseCamera: "Nepavyko panaudoti kameros, patikrinkite naršyklės leidimus",
      processingOnWebApi: "Informacija apdorojama...",
      documentSidesMismatched: "Dokumento pusės nesutampa",
    },
    dateRangePicker: {
      noDateSelected: "data nepasirinkta",
      from: "nuo",
      until: "iki",
      interval: "intervalai",
      choose: "pasirinkti",
      currentMonth: "šis mėnuo",
      lastMonth: "praeitas mėnuo",
      thisWeek: "ši savaitė",
      lastWeek: "praeita savaitė",
      required: "Laukelis privalomas",
      invalidDateRange: "Data turi būti nuo {0}",
    },
    websiteDisplaysBetterInOtherLanguages: {
      messageRow1: "Gerb. partneri,",
      messageRow2:
        "pastebėjome, kad naudojate Internet Explorer naršyklę. Ši naršyklė yra pasenusi ir daugelio LPS funkcionalumų nebepalaiko, todėl prašome jungiantis naudoti kitas naršykles (Microsoft Edge, Chrome, Firefox ir pan.).",
    },
    errors: {
      lengthRule: "Slaptažodį turi sudaryti ne mažiau kaip",
      caseRule: "Slaptažodyje turi būti naudojama bent viena",
      specialCharRule: "Slaptažodyje turi būti naudojamas bent vienas",
      lengthRuleValue: "8 simboliai",
      lowerCaseRuleValue: "mažoji raidė",
      capitalCaseRuleValue: "didžioji raidė",
      numberRuleValue: "skaičius",
      specialCaseRuleValue: "specialus simbolis",
    },
    searchableSelect: {
      addOption: "Naudoti kaip naują įrašą",
    },
    contractRequests: {
      statusWAITING: "Laukia",
      statusCONFIRMED: "Patvirtintas",
      statusREJECTED: "Atmestas",
      statusESCALATED: "Eskaluotas",
      statusEXPIRED: "Nebegaliojantis",
      valid: "Galioja",
    },
    validationSummary: {
      errors: "Klaidų suvestinė",
    },
    personalData: {
      documentNumberValidation: {
        format8digits: "Dokumento numeris turi turėti lygiai 8 simbolius",
        format9digits: "Dokumento numeris turi turėti lygiai 9 simbolius",
        format8Or9digits: "Dokumento numeris turi turėti 8 arba 9 simbolius",
        format2letters7digits: "Dokumento numeris turi turėti lygiai 9 simbolius, pirmi du gali būti raidės",
      },
    },
    incomeList: {
      type: "Tipas",
      source: "Šaltinis",
      name: "Pavadinimas",
      dateFrom: "Data nuo",
      dateUntil: "Data iki",
      incomeFromSource: "Pajamos iš šaltinio",
      byManager: "Įveda vadybininkas",
      addEntry: "Pridėti",
      calculated: "Apskaičiuotos",
      provided: "Pateiktos",
      valuable: "Vertinamos",
      skipDate: "Nenaudoti",
    }
  },
  en: {
    meta: {
      titles: {
        login: "Joining",
        applications: "Applications and Contracts",
        news: "News",
        newApplication: "New application",
        newApplicationStep2: "New Application",
        newApplicationStep3: "New Application",
        newApplicationStep4: "New Application",
        newApplicationStep5: "New application",
        editApplicationStep2: "Application / Contract",
        editApplicationStep3: "Application / Contract",
        editApplicationStep4: "Application / Contract",
        editApplicationStep5: "Application / Contract",
        searchApplications: "Search",
        accountSettings: "Account management",
        passwordChanged: "password changed",
        reports: "Reports",
        spreadsheet: "Spreadsheet",
        accounts: "Accounts",
        accountEdit: "Edit account",
        accountCreate: "Create account",
        recognizer: "Document recognizer",
        debtReport: "Debt statement",
        pageNotFound: "Page not found",
        passwordRemind: "Password reminder",
        passwordUpdate: "Password update",
        verifyDevice: "Device verification",
        autoLoginLimited: "Quick application editing",
        autoLogin: "Fast editing",
        iframeLogin: "Login",
      },
    },
    login: {
      userName: "user name",
      password: "password",
      forgotPassword: "forgot password",
      login: "login",
      loginIn: "login",
      logOut: "sign out",
      remindPassword: "remind password",
      remind: "remind",
      userEmail: "e-mail",
      userPhone: "phone number",
      forgotPasswordCallHelp: "Or call",
      checkEmail: "Thank you. Check your email for instructions!",
      confirmSmsCode: "Enter the code received by the SMS message",
      confirmEmailCode: "Enter the code received by the e-mail",
      verifySmsCode: "Confirm",
      smsCode: "code",
      sessionExpires: "You have been inactive for {sessionTimeout}, please log in again.",
      changePassword: "Change password",
      updatePassword: "update the password",
      passwordConfirmation: "Repeat password",
      passwordNotMatch: "Passwords do not match",
      passwordChanged: "The password has been changed!",
      verificationTokenMissing: "user confirmation code error",
      verificationLoginMissing: "user name validation failed",
      attempts: " attempts",
      contactUs: "If you fail to sign in, please contact us.by mail <a href='mailto:komercija@sbl.lt'>komercija@sbl.lt</a> or by phone <a href='tel:+37037409156'>8-37 40 91 56</a>",
      updatePasswordWarn: "password expiration date. Please change your password for data security.",
      tryResetLater: "The password restoration feature can only be used once per 3min. Please try later.",
      verifyDevice: "verify device",
      verifyDeviceNote: "you are connecting from an unrecognizable (new) device, so you need to verify it.",
      deviceConfirmTimeout: "You can try sending code after ",
      deviceConfirmRepeat: "Send a new code",
      sendingErrorSms: "SMS message could not be sent. Try again later.",
      sendingErrorEmail: "Email could not be sent. Try again later.",
      dataUpdate: "data update",
      dataUpdateWarning: "update contact details and password please",
      saveData: "save data",
      dataUpdatedSuccessfully: "data updated successfully",
      passwordRemindDummyEmail:
        "If the account is registered, we will send you email letter with further instructions.",
      passwordRemindDummySMS:
        "If the account is registered, we will send the approval code via SMS.",
      provideLoginTooltip: "If You forgot Your user name, please contact us.by mail <a href='mailto:komercija@sbl.lt'>komercija@sbl.lt</a> or by phone <a href='tel:+37037409156'>8-37 40 91 56</a>",
    },
    sont: {
      sendingErrorSms: "SMS message could not be sent. Try again later.",
      sendingErrorEmail: "Email could not be sent. Try again later.",
    },
    soap: {
      undefined: "SOAP system error",
      "-404": "Network error. Please try again later.",
      0: "Everything is fine",
      1: "sistemosKlaida", //InternalWsApiError [notAResponseFromPvs]
      5: "Cannot enter into a contract",
      10: "In the case of zero income",
      1000: "Invalid Password",
      1001: "Invalid Login",
      1002: "Invalid Login",
      1004: "Your account is disabled. To unlock the account, please use the password remind form, or ask the master account holder",
      1005: "Your account is disabled. To unlock the account, please use the password remind form, or ask the master account holder",
      1006: "The new password must not match the old one.",
      1020: "Invalid session",
      1021: "Action denied",
      1030: "New client not found in DB, name required",
      1031: "Invalid personal code",
      1032: "Do not serve this customer",
      1050: "A user with such information not found",
      1051: "More than one user found. Please contact us by e-mail <a href='mailto:kommercija@sbl.lt'>kommercija@sbl.lt</a> or phone no. <a href='tel:837409156'> 8-37 40 91 56",
      1060: "Bad Application Id",
      1063: "Application not found",
      1064: "Cant delete Application",
      1065: "The application number is already assigned to contract",
      1080: "Manager Not Found",
      1090: "No users matching the key",
      1100: "Incorrect status of the contract",
      1200: "The contract can no longer be rejected",
      1300: "Application action log is empty",
      1400: "Under the conditions set out, it is not possible to calculate the schedule [at the same time to conclude the contract].",
      1500: "Unknown Report",
      1600: "Failed to approve contract",
      1800: "Failed to count suggestions",
      1900: "No values found",
      2000: "System error",
      2001: "DB returned an empty response",
      2002: "PDF Generation Error",
      2101: "Failed to change contract status by rejecting",
      2120: "Error saving seller",
      2140: "Seller has no custom terms in effect.",
      2200: "Document is in archive.",
      2401: "The permit has already been abolished earlier.",
      2402: "There was no evaluation on the basis of such permission.",
      2403: "Do not have the right to approve such a request.",
      2404: "Such a request has already been created earlier.",
      2405: "There was no evaluation on the basis of such conditions.",
      2600: "Failed to upload the file",
      // custom LPS codes
      900: "Such username or email already exists",
      contractSaved: "Paraiška išsaugota",
      signedAgreementDocumentSuccess: "Agreement document signed successfully",
      signedContractDocumentSuccess: "Contract document signed successfully",
      left: "Left ",
    },
    ecr: {
      signRequestOnEcrFailed: "Failed to initiate signing",
      spouseSignRequestOnEcrFailed: "Failed to initiate spouse signing",
    },
    applications: {
      title: "Applications and contracts",
      accountNumber: "account no.",
      additionalAccount: "additional account",
      buyer: "buyer",
      buyerFullName: "the name of the seller",
      buyerName: "buyer's name",
      buyerPersonalCode: "buyer's personal code",
      buyerSurname: "the name of the buyer",
      code: "code",
      contractConditionVariant: "a variant of the terms of the contract",
      paymentDay: "payment day",
      contractDate: "date of contract",
      contractDurationMonths: "contract duration in months",
      contractNumber: "contract no.",
      draftNumber: "draft agreement ID",
      contractType: "type of contract",
      createDate: "date of conclusion",
      creditLimit: "credit limit",
      creditSum: "credit amount",
      eCreditStatus: "E-credit status",
      filter: "filter",
      hasIncomeCertificate: "is a certificate of income",
      incomeType: "income type",
      lowerWinterFee: "lower premiums in winter",
      number: "no.",
      product: "product",
      products: "goods",
      seller: "seller",
      shopName: "store name",
      signatureType: "application signature method",
      contractSignatureType: "contract signature method",
      spouseContractSignatureType: "spouse contract signature method",
      statusAll: "all",
      application: "application",
      archived: "archive",
      unsignedContract: "unsigned contract",
      withDefects: "defective contract",
      signedContract: "contract",
      rejectedContract: "contract rejected",
      contract: "contract",
      status: "status",
      total: "total records",
      changeVisibleFields: "Change the display of columns",
      fieldSettingsInfo: "* settings are instantly saved in Your browser's memory",
      dateFrom: "date from",
      dateTo: "date to",
      exportXlsx: " Export",
      useWideTable: "Wide table",
      sureToRemove: "Are you sure you want to delete?",
      sellerFullName: "Seller's name and surename",
      comment: "comment",
      moreFilters: "more filters",
      fewerFilters: "fewer filters",
      invalidDateRange: "Date must be greater than {dateFrom}",
      openEcreditSession: "Open e-credit session",
    },
    newApplication: {
      title: "new application",
      sellerInfo: "seller info",
      actionLog: "Action log",
      download: "To download the contract, cancel the electronic signature",
      cancellation: "The contract may not be rejected until the electronic signature has been revoked",
      lastStep: "The last step",
      chooseSeller: "choose seller",
      next: "next",
      back: "back",
      companyName: "company name",
      seller: "seller",
      choose: "Choose",
      buyerInfo: "buyer information",
      personalCode: "personal code",
      spousePersonalCode: "spouse personal code",
      creditType: "credit type",
      creditTypeNoSpouse: "has no spouse",
      creditTypePersonal: "personal credit",
      creditTypeSpouse: "family credit",
      creditTypeTooltips: {
        "option-0": "evaluate without a spouse",
        "option-1": "evaluate without a spouse",
        "option-2": "evaluate with the spouse",
      },
      change: "change",
      spouseInformation: "spouse information",
      contractNumber: "contract number",
      phone: "phone",
      draftNumber: "draft agreement ID",
      phoneNumber: "phone number",
      phoneNumberShort: "phone",
      personalCodeShort: "p.c.",
      spousePhoneNumber: "spouse phone number",
      company: "companies",
      update: "update",
      applicationDetails: "application details",
      search: "search",
      searchDanger: "no users matching the key",
      clientProvidedData: "customer's data",
      canIncomeCollapse: "can revenue fall?",
      catalogWithException: "Apply the promotion exception",
      yes: "yes",
      no: "no",
      income: "income",
      clientIncome: "client's income",
      spouseIncome: "spouse's income",
      spouseDataObtainMethod: "Spouse data obtaining method",
      fillSpouseData: "fill details",
      provideSpouseDataOnline: "spouse will fill in and submit the data remotely",
      provideSpouseDataInfo: "We will send a link to sign the consent to the specified spouse's contacts.",
      dataObtainMethod: "fill details",
      fillData: "fill details",
      provideDataOnline: "client will fill in and submit the data remotely",
      provideDataInfo: "We will send a link to sign the consent to the specified contacts.",
      incomeType: "income type",
      spouseIncomeType: "spouse's income type",
      unsignedDocuments: "unsigned documents",
      printDocuments: "print",
      emailDocuments: "send emailby mail",
      incomeDeclarationExists: "there is a certificate for about 4 months income",
      workplace: "workplace",
      consumerCreditsPerMonth: "consumer credits, Eur / month",
      mortgagesPerMont: "housing loans, Eur / month",
      creditUserIsMe: "i will use the credit myself",
      politicalActivity: "political activism",
      marketingAgreements: "marketing consents",
      spouseMarketingAgreements: "spouse's marketing consents",
      directMarketing: "direct marketing",
      partnerOffers: "partner offers",
      dataProfiling: "data profiling",
      spouseDirectMarketing: "spouse'e direct marketing",
      spousePartnerOffers: "spouse'e partner offers",
      spouseDataProfiling: "spouse'e data profiling",
      directMarketingTooltip: "Consent to SB companies to process personal data (name, telephone number, email, address) for direct marketing purposes in order to provide notifications and offers about the services provided, and ask for the quality of service and service.",
      partnerOffersTooltip: "Consent to SB companies to process personal data (name, telephone number, email, address) in order to submit proposals for the goods or services of selected partners. Topical lists of SB companies can be found here <a href=\"https://www.sb.lt/imoniu-partneriu-sarasas\" target=\"_blank\">www.sb.lt/imoniu-partneriu-sarasas</a>",
      dataProfilingTooltip: "Consent to SB companies to submit direct marketing offers, personalize (including profile) personal data and automatically conduct related properties;Also share these data with other SB companies to provide needs and offer a personally suitable service, product.",
      dataProfilingDisabledTooltip: "Does not apply because it has not been met with the receipt of direct marketing offers",
      application: "application",
      spouseApplication: "spouse application",
      print: "print",
      signOnTablet: "sign on tablet",
      signRemotely: "sign remotely",
      signOnTabCancel: "cancel signing",
      incomeTypeHiredWorker: "hired worker",
      incomeTypePensioner: "pensioner",
      incomeTypeOther: "other",
      incomeTypeOfficer: "officer",
      incomeTypeHiredFarmer: "farmer",
      name: "name",
      surname: "surname",
      spouseName: "spouse name",
      spouseSurname: "spouse surname",
      email: "e-mail",
      spouseEmail: "spouse e-mail",
      optional: "(neprivalomas)",
      emailTooltip:
        "This e -mail will be used to send information to customers about the processing of their personal data and copies of consumer credit agreement and other information documents.",
      agreementFileName: "agreement",
      signedAgreementFileName: "agreement-signed",
      agreementFileNameSpouse: "spouse-agreement",
      signedAgreementFileNameSpouse: "spouse-agreement-signed",
      incomePlanned: "projected future income",
      spouseIncomePlanned: "projected future income of the spouse",
      politicianNameSurname: "the name of the politician",
      politicalInstitution: "political institution",
      politicianConnection: "politician connection",
      politicianMyself: "the customer himself",
      politicianFamilyMember: "family",
      politicianColleague: "colleague",
      documentsForPrinting: "documents",
      creditData: "credit data",
      financialLiabilities: "financial liabilities",
      personalData: "personal data",
      goods: "goods",
      goodsMissing: "the goods must be present and goods prices sum should be above 0",
      addEditGoods: "Add / Edit good",
      additionalData: "additional data",
      contractConfirmation: "confirmation of the contract",
      uploadDocument: "upload the document",
      contractProject: "the draft agreement",
      printInsuranceAgreement: "Print consent",
      printInsuranceAgreementPopover: "Answer all the questions",
      printInsuranceAgreementNotGrantedPopover: "There are no necessary consent",
      sendClientDocuments: "send to customer doc",
      showMaxPosibleCredit: "show the greatest possible credit",
      saveData: "save data",
      saveApplication: "save the application",
      commonInformation: "common information",
      initiateContract: "initiate contract",
      abortContract: "reject contract",
      documentPackage: "document package",
      agreement: "agreement",
      agreementSpouse: "spouse agreement",
      contract: "contract",
      contractSpouse: "spouse contract",
      evaluationReport: "assessment report",
      debtReport: "debt report",
      debtReportSpouse: "spouse's debt report",
      sign: {
        UNKNOWN_SIGN: "-",
        PAPER_SIGN: "on paper",
        CENT_SIGN: "pavedimu",
        E_SIGN: "e.Sign",
        TABLET_SIGN: "on tablet",
        INI_SIGN: "initiated e signing",
        INI_TABLET_SIGN: "initiated by el. signing on tablet",
      },
      actionLogFields: {
        dateEndTime: "Date and time",
        user: "User",
        final: "Good luck",
      },
      ecreditStatus: {
        normal: "normal course",
        signedContract: "signed contract",
        signedApplication: "signed application",
        reviewedByManager: "reviewed by a manager",
        terminated: "terminated",
        none: "none",
      },
      attachFiles: "attach files",
      chooseFile: "select",
      dropFiles: "drop files here",
      or: "or",
      useTabletCamera: "use tablet camera",
      fileUploadDisclaimer: "make sure the uploaded picture is not fused and the document data is clearly readable",
      exceededFileLimitError: "maximum {0} files can be uploaded at once",
      exceededFileLimitWarning: "file limit reached: No more than {0} files can be uploaded at once",
      warningAboutSelectedFile: "some files could not be selected. Allowed file extensions: {0}. Maximum file size {1} Mb",
      failedFileDispaly: "file can not be viewed",
      selectDocumentType: "select type",
      documentTypeNotSelected: "document type is not selected",
      save: "save",
      documentType: "document type",
      sum: "sum",
      advancePayment: "advance payment",
      ownFunds: "own funds",
      invalidValue: "invalid value",
      ownFundsTooltip: "The advance payment is made directly to the seller",
      advancePaymentTooltip: "The advance amount is paid to the leasing account",
      transferToClient: "transfer to client",
      duration: "duration",
      paymentDay: "payment day",
      smallWinterPayments: "lower payments in winter",
      maxCreditPossibilities: "max credit possibilities",
      searchingForProposedOptions: "we are looking for credit options",
      monthlyPayment: "monthly installment",
      maxCreditSum: "Maximum credit amount",
      paymentSchedule: "Payment schedule",
      monthShort: "mon.",
      creditInterest: "interest",
      contractFee: "contract fee",
      administrationFee: "administration fee",
      totalCreditAmountValue: "the total amount of credit",
      conditionsOption: "conditions option",
      accountNumber: "account No.",
      creditorAccountNumber: "creditor account No",
      moneyUsage: "credit purpose",
      dropDownChoose: "choose",
      source: "source",
      typeOfContract: "type of credit",
      dateOfContract: "date of the contract",
      totalOutstandingAmount: "balance with interest.",
      limitAmount: "credit limit",
      outstandingInstalmentCount: "remained contribution",
      instalmentCount: "number of contributions",
      installmentAmount: "down payment",
      refinancePossible: "ref",
      installmentAmountCorrected: "the adjusted down payment",
      overdueDays: "overdue days",
      purpose: "purpose",
      contractType: {
        mortgage: "mortgage",
        creditCards: "card",
        leasing: "leasing",
        consumer: "consumer",
        other: "other",
      },
      creditor: "creditor",
      refinanceAmount: "refin. sum",
      payerCode: "buyer code",
      emptyFinancialLiabilities: "there is no data on financial liabilities",
      total: "total",
      totalInRegistries: "in registries",
      totalByClient: "customer provided",
      totalEstimation: "assessed down payment",
      totalEstate: "mortgage credit down payment",
      totalConsumer: "consumer credit down payment",
      goodTitle: "good",
      goodGroup: "group",
      goodSerialNo: "Products / Serial No.",
      goodPrice: "price",
      goodQuantity: "amount",
      extendedWarranty: "extended warranty",
      goodAmount: "amount",
      insured: "yes",
      noInsurance: "no",
      goodsTotalPrice: "total goods amount",
      goodsTotalWarranty: "extended warranty of the goods amount",
      city: "city",
      street: "street",
      house: "house",
      flat: "apartment",
      postalCode: "post code",
      isAddressUsageDifferent: "The address of the place of residence is different from correspondence",
      documentValidUntil: "valid until",
      documentValidUntilValidation: "Value should be between {from} and {to}",
      documentNumber: "document number",
      spousePersonalData: "spouse's personal data",
      personalAddressTitle: "correspondition address",
      personalDocumentTitle: "document",
      documentControlLine: "control line",
      citizenship: "citizenship",
      documentValidationNumber: "control line",
      additionalAccount: "additional account",
      additionalCode: "additional code / note",
      contractSigning: "signing the contract",
      applicationSigning: "signing the application",
      contractComment: "comment",
      creditCardData: "credit card data",
      creditCardOwner: "credit card owner",
      cardHolder: "card holder",
      pickupAddress: "pickup address",
      reviewChatHistory: "view chat history",
      contractSigned: "contract signed",
      electronicSigning: "el. signing",
      cancelElectronicSigning: "cancel el. signing",
      standardInformationFileName: "standard-information",
      pay: "pay",
      contractApprove: "confirm the contract",
      contractDiscard: "reject the contract",
      confirmContractCancellation: "Reject the contract",
      sureToCancelContract: "Reject contract number {contract} ?",
      abortCancellation: "cancel",
      contractConditionsCompletion: "contract condition completion",
      errorAmountTooLarge: "the amount is greater than the amount of goods",
      failedToLoad: "application not found",
      controlLineNumbersInvalid: "control numbers for the document are incorrect",
      scanDocument: "scan document",
      scanProductsQr: "scan product",
      readDeviceCamera: "use the camera of this device",
      readUploadedImage: "upload a photo of the document",
      readViaQR: "use the other device camera",
      problemWithQRScan: "Scanning the document failed",
      readQRInstructions: "please open the exchange link on your smartphone with QR reader",
      incorrectFyleType: "File type is not recognized, supported formats are JPEG/JPG, PNG or PDF",
      combinedIncomeValidationError: "The amount of family income must be greater than 0",
      incorrectAdvanceValue: "The advance value must be correct",
      sbInsuranceValue: "SB Insurance Value",
      chooseValidCondition: "Choose valid credit condition",
      chooseValidConditionByGoods: "Choose available credit condition",
      pickAmountOrCondition: "Choose a different amount or conditions",
      contractInitiationAllowed: "Allowed to initiate contract!",
      contractMade: "Contract made!",
      contractApproved: "Contract approved!",
      contentNotFound: "Content not found",
      permissionDenied: "You do not have permission to view this page",
      fileUpload: {
        personalDocCopy: "Personal document copy",
        incomeDeclaration: "Reference of wage",
        idFrontSide: "ID card front",
        idBackSide: "ID card back",
        passport: "Passport",
        spouseIdFrontSide: "Spouse ID card front",
        spouseIdBackSide: "Spouse ID card back",
        spousePassport: "Spouse's passport",
        driverLicenseFront: "Driver license front",
        driverLicenseBack: "Driver license back",
        spouseDriverLicenseFront: "Spouse driver license front",
        spouseDriverLicenseBack: "Spouse driver license back",
        sbInsuranceDoc: "Sat insurance doc.",
        refinanceDoc: "Refinancing doc.",
        otherDoc: "Other",
        residencePermitFront: "Residence permit document front",
        residencePermitBack: "Residence permit document back",
        spouseResidencePermitFront: "Spouse's residence permit document front",
        spouseResidencePermitBack: "Spouse's residence permit document back",
        residencePermitTemporaryFront: "Temporary residence permit document front",
        residencePermitTemporaryBack: "Temporary residence permit document back",
        spouseResidencePermitTemporaryFront: "Temporary spouse's residence permit document front",
        spouseResidencePermitTemporaryBack: "Temporary spouse's residence permit document back",
        electronicResidencePermitCheckPhoto: "e-permit check image",
        spouseElectronicResidencePermitCheckPhoto: "Spouse's e-permit check image",
        decisionApprovalDocument: "Decision approval doc.",
        bankAccountReport: "Bank statemenet",
        spouseBankAccountReport: "Spouse's bank statemenet",
        applicationCopy: "A copy of the application",
        applicationMarketingAgreementCopy: "Copy of Application Marketing Sheet",
        spouseApplicationCopy: "A copy of the spouse's application",
        spouseApplicationMarketingAgreementCopy: "A copy of the spouse's application for marketing sheet",
        barCodeNotExtracted: "unrecognized bar code",
        errorExctractingBarCode: {
          30: "Error when loading a copy of the application",
          31: "Error when loading a copy of the spouse's application",
          32: "Error when loading a copy of the application leaf",
          33: "Error when loading a copy of the spouse's application for a marketing sheet",
        }
      },
      documentUploads: {
        needToUploadDocuments: "Upload the selected types of documents into the system and try to sign again",
        uploadPersonalDocument: "Upload a copy of your identity document",
        uploadPersonalDocumentSpouse: "Upload a copy of your spouse's identity document",
        uploadPassport: "Upload a copy of your passport",
        uploadPassportSpouse: "Upload a copy of your spouse's passport",
        uploadResidencePermit: "Upload a copy of your residence permit",
        uploadResidencePermitSpouse: "Upload a copy of your spouse's residence permit",
        documentUploadedSuccessfully: "Document was uploaded",
        useGlobalDocumentType: "Apply to all uploaded files",
        provideDocumentCopiesForTheseFiles: "Upload copies of these documents",
      },
      fileUploaded: "the document is uploaded",
      proposalsAdditionalInfo: "Notifications related to the application",
      cantChangePersonalCode: "personal code can not be changed",
      invalidPersonalCode: "personal code field value is invalid",
      invalidSpousePersonalCode: "spouse personal code field value is invalid",
      signOnTabletBeforeContinuing: "sign agreement(s) on the tablet and continue signing the contract",
      sbInsurancePayment: "SB Insurance details",
      insuranceQuestions: {
        one: "The Customer agrees that in order to be included in the insurance contract between the lender and the insurer, the lender and insurer would process his or her health data specified in the health declaration, the insured's long survey questionnaire and the other creditor or insurerin the documents received, for the inclusion of the insurance contract (including the insurance risk assessment, the calculation of the insurance premium) and enforcement (including the administration of insured or non -insured events).",
        two: "The client agrees that the insurer would provide him with identification and contact details, as well as health data (including information on health status, diagnoses, forecasts, research results, treatment) to health care and medical institutions, law enforcement facilities and institutions, other companies, other companies, other companies,to institutions and organizations (including health data to reinsurance companies, insurer's selected service providers) and the customer's identification and contact details received from them, as well as health data (including information on health status, diagnoses, forecasts, research results, treatment) as muchThis is required for the inclusion of the Customer in the insurance contract (including the reconciliation of the terms of the insurance contract, the insurance risk assessment, the amount of the insurance premium) and the execution of the insured or non -insured events).Customer's personal data from public registers.",
        three: "Does the client currently have any health problems or symptoms that have not yet been treated for treatment? Maybe they are planning to see a doctor or have a medical examination (except for a slight cold, influenza or other minor viral illnesses)?",
        four: "Did the client take / take medication in the last 2 years, or was it recommended / prescribed for medication (except vitamins, nutritional supplements and cases of slight colds or flu, nervous headache)?",
        five: "Has the client has been ill in the last 10 years / have been diagnosed with cardiovascular, oncology, diabetes, epilepsy or other nervous system diseases, lung disease, mental behavioral disorders, kidney or associated diseases, hepatitis B, C, C, tuberculosis, HIV, etc.?",
        six: "Is the client currently being treated for physical and mental disabilities or chronic illnesses or any type of illness/injury?",
        seven: "Is the client a surgery or treatment at a health care or treatment facility?",
        eight: "Are the client a physician prescribed or have been conducted by the client, the results of which are not yet unknown?",
        nine: "Has the client been diagnosed with: heart or lung disease/failure, diabetes, epilepsy or other types or seizures, nervous disorders, kidneys or associated diseases, high or low blood pressure, oncological illnesses, HIV, AIDS, anotherNature disability?",
      },
      healthDataAgreement: "Consent to the processing of health data",
      healthDataDetails: "Health questions",
      contractRequest: "contract requests",
      contractRequests: {
        time: "time",
        sum: "amount",
        advancePayment: "advance payment",
        duration: "duration",
        conditionVariant: "credit conditions variant",
        creditType: "credit type",
        status: "status",
        initiator: "initiator",
        closer: "closer",
        note: "note",
        details: "Details",
      },
      newContractRequest: "request permit",
      reject: "Reject",
      confirm: "Confirm",
      leaveComment: "Leave comment",
      spouseNoteUnknownSign: "By pressing the \"Next\" button, we will send the link to sign the application.",
      spouseNoteIniSign: "Looking forward to signing the application",
      noteUnknownSign: "By pressing the \"Next\" button, we will send the link to sign the application.",
      noteIniSign: "Looking forward to signing the application",
      insuranceDeposit: "Apply SBD's recalculated contribution",
      insuranceDepositRecalculated: "SBD expert recalculated contribution",
      insuranceDepositTooltip: "Select \"Yes\" only when the information from SBD experts with a recalculated insurance premium (depending on the amount and duration of the credit desired by the Customer) was asked and received.",
      insuranceDepositAdjustsExpert: "SB insurance deposite adjusted by the expert",
      noValidationIssues: "There are no errors in the form",
      validationIssuesExists: "There are errors in the form",
      invalidValueInField: "Field {field} value is invalid",
      repeatElSign: "Send link again",
      repeatElSignSpouse: "Send spouse link again",
      generateBankReport: "Bank statement generation",
      generateBankReportSpouse: "Spouse's bank statement generation",
      generateBankReportButton: {
        "0": "Send link",
        "1": "Waiting",
        "2": "Received",
        "3": "Repeat",
      },
      generateBankReportButtonTooltip: {
        "0": "Request customer's bank statement to assess other customer income",
        "1": "Request was sent to the client",
        "2": "Apply to SB leasing manager for additional income in a bank statement",
        "3": "Unable to generate bank statement, repeat the process",
      },
      signaturesMissing: "The application must be signed. Print or initiate signing on a tablet",
      spouseSignaturesMissing: "The spouse's application must be signed. Print or initiate signing on a tablet",
      popoverDocumentPack: "Download a package of documents",
      popoverSignOnTab: "To initiate a contract signing on a tablet",
      popoverSignRemotely: "Send a link to sign the contract remotely",
      popoverRepeatSignRemotely: "Repeatedly send the link to sign the contract remotely",
      popoverContractSigned: "To confirm the signing of the contract",
      contractSignedConfirmation: "By pressing the button below, you confirm that the contract was signed on paper and all signatures in the right places."
    },
    news: {
      title: "News",
      hello: "Hello",
    },
    searchApplications: {
      title: "Search",
      error: "enter application number",
    },
    accountSettings: {
      title: "settings",
      accountDetails: "account details",
      submitChanges: "change details",
      changePassword: "change password",
      passwordChanging: "password changing",
      createAccount: "Create an account",
      name: "name",
      surname: "surname",
      email: "e-mail",
      spouseEmail: "spouse e-mail",
      newPassword: "new password",
      passwordConfirmation: "repeat the password",
      saved: "information updated",
      phone: "phone number",
      authenticationMethod: "authentication",
      authenticationByEmail: "by email",
      authenticationBySMS: "by SMS",
      authenticationUndecided: "not selected",
      userName: "Username",
      shop: "Shop",
      notePersonalInfo: "for a change contact komercija@sbl.lt",
      verifyEmail: "enter email verification code",
      verifySms: "enter sms verification code",
      verifyContacts: "verify contacts",
      enter: "enter",
      sendingErrorSms: "sending verification code via sms failed. Try again later.",
      sendingErrorEmail: "sending verification code via email failed. Try again later.",
      tooMuchChanges: "You can not change phone and email at the same time.",
      codeWasSent: "The code was sent. To get a new code you must wait for the code to expire.",
      sharedAccountNote: "Attention! Amending a common store user settings that may affect all users on their behalf."
    },
    reports: {
      title: "applications",
      preview: "preview report",
      download: "download report",
      noAvailableReports: "There are no reports that could be viewed."
    },
    spreadsheet: {
      title: "calculator",
      conditions: "conditions",
      extendedForm: "extended form",
      shortForm: "short form",
      duration: "duration, month.",
      amount: "amount, Eur",
      calculate: "calculate",
      reset: "reset",
      results: "calculation results",
      purpose: "purpose",
      familyMembersCount: "family members count",
      netIncome: "income after taxes, Eur/month",
      spouseNetIncome: "spouse income after taxes, Eur/month",
      monthlyCreditPayments: "monthly credit payments",
      mortgagePayments: "monthly morgage payments",
      birthDate: "birthday",
      showMaxCredit: "show max possible credit",
      createApplication: "create application",
      monthlyPayment: "monthly payment",
      sumOfPayments: "sum of payments",
      interestRate: "interest rate",
      bkkmn: "BKKMN",
      contractFee: "contract fee",
      administrationFee: "administration fee",
      totalCreditAmount: "credit amount",
      term: "term",
      period: "period",
      unpaidCredit: "unpaid credit",
      creditPayment: "credit payment",
      interestPayment: "interest payment",
      totalContribution: "total contribution",
      secondCredit: "second credit",
      firstCreditPayments: "first credit payments",
      paymentScheduleFileName: "payment-schedule",
      incompleteListDisplayed: "note! An incomplete list is displayed",
    },
    iframeLogin: {
      formTitle: "For business partners",
      passwordRemindLink: "Password reminder"
    },
    footer: {
      // eslint-disable-next-line quotes
      rightsReserved: '© UAB "SB lizingas". All rights reserved',
    },
    header: {
      updateNote: "We were renewed. You can reach the old version at the address",
    },
    goodsModal: {
      heading: "Change goods",
      goods: "goods",
      good: "good",
      serialNo: "Goods / Serial no.",
      group: "Products group",
      price: "price",
      quantity: "amount",
      addProduct: "Add good",
      scanCode: "scan",
      save: "Save",
      purchaseInsurance: "Purchase insurance",
      warrantyDuration: "Manufacturer's warranty",
      warrantyExtension: "Extended warranty",
      year: "y",
      warrantyExtensionSelected: "After choosing the extended warranty, this product group is not available",
      priceBetweenError: "The goods price must be between ${min} and ${max}",
      serialNoRequired: "When choosing an extended warranty, it is mandatory to enter",
      quantityCantBeChanged: "Once the extended warranty is selected, no change is allowed",
      extendedWarrantyRequired: "Required to select extended warranty duration",
      emptyCatalogSerialNoRequired: "Product No. is required",
      minSerialNoLength: "Product No. must be atleast {min} symbols",
      continue: "Continue",
      managerInfo: "It is not necessary to adjust the list of the manager's role consumer.The changes must be coordinated with the seller.",
    },
    serviceWorker: {
      updated: "The system has been renewed",
      reloadMessage: "System updates are ready. The changes will activated with the vizit of a new page or on reloading the existing one.",
      reload: "Reload now",
    },
    accounts: {
      list: {
        title: "Accounts",
        name: "Name",
        username: "Username",
        email: "Email",
        role: "Role",
        active: "Active",
        store: "Store",
        lastLogin: "Last login",
        yes: "Yes",
        no: "No",
        show: "Show",
        records: "records",
        search: "Search",
        edit: "Edit",
        create: "Create account",
        deactivate: "Deactivate",
        confirmDeactivate: "Are you sure?",
        actions: "Actions",
        unblock: "unblock",
        unblockedSuccessfully: "user unblocked successfully",
      },
      edit: {
        title: "Edit account",
        loadFailed: "A user with such information not found",
        passwordError:
          "The password must use: capital letters; lowercase letters; special characters (~! @ # $% ^ & * () _ + [] \\; ',. / {} |: \"<>?);",
      },
      create: {
        title: "Create account",
      },
      warrant: {
        warn: "Attention! Please confirm warrant employees in ",
        warn2: "page, to prevent them to be disabled after ",
        warn3: " .",
        confirmMessage1: "Confirm below employess to allow them to administer the loan contracts?",
        confirm: "Confirm",
        confirmMessage2: "Please deactivate those employees, who are not working at the company anymore.",
        accounts: "Accounts",
      },
    },
    roles: {
      0: "Seller",
      1: "Manager",
      2: "Supervisor",
    },
    contractTypes: {
      goods: "Credit goods contract",
      3: "Credit goods contract",
      creditAccount: "Credit account contract",
      5: "Credit account contract",
      creditCard: "Credit card contract",
      6: "Credit card contract",
      credit: "Online contract",
      7: "Online contract",
    },
    mazosImokos: {
      0: "No",
      1: "Yes",
    },
    pajamuPazyma: {
      0: "No",
      1: "Yes",
    },
    tableInfo: {
      totalRecordsDisplayed: "Displayed records from {startRecord} to {endRecord} of {totalRecords}",
      noPostsFound: "Records not found",
      totalResults: " (selected from {totalResults} records)",
      noteAboutResults: "If you want to see other records, narrow your search criteria.",
    },
    microblink: {
      pointDocument: "Point the camera to the front side of the document.",
      pointQr: "Point the camera to the barcode.",
      documentDetected: "The document was detected",
      documentNotDetected: "Detection failed",
      recognitionFailed: "Document recognition failed, try again",
      qrFailed: "The product barcode could not be scanned, please try again",
      scanning: "Scaninng...",
      rotateDocument: "Tilt the document",
      flipDocument: "Turn the document",
      zoomIn: "Zoom in the document",
      zoomOut: "Zoom out the document",
      unhandledStatus: "Unidentified scan status!",
      provideSpouseDocument: "Scan the spouse's document or enter personal code",
      documentRecognized: "Document recognized",
      qrRecognized: "Product barcode scanned",
      licenseError: "Document and goods recognition service is currently unavailable. Please try again later.",
      qrCodeScanned: "QR code used, waiting for the data...",
      unableToUseCamera: "Unable to use camera device, check browser permissions",
      processingOnWebApi: "Information is currently processed...",
      documentSidesMismatched: "The sides of the document do not match",
    },
    dateRangePicker: {
      noDateSelected: "no date selected",
      from: "start",
      until: "end",
      interval: "periods",
      choose: "choose",
      currentMonth: "current month",
      lastMonth: "last month",
      thisWeek: "this week",
      lastWeek: "last week",
      required: "Field required",
      invalidDateRange: "Date must be later than {0}",
    },
    websiteDisplaysBetterInOtherLanguages: {
      messageRow1: "Website displays better on modern browsers",
      messageRow2:
        "We have noticed that you are using an Internet Explorer browser.This browser is outdated and does not support many LPS functionality, so please use other browsers (Microsoft Edge, Chrome, Firefox, etc.).",
    },
    errors: {
      lengthRule: "Password must contain at least one",
      caseRule: "Password must contain at least one",
      specialCharRule: "Password must contain at least one",
      lengthRuleValue: "8 characters long",
      lowerCaseRuleValue: "lowercase letter",
      capitalCaseRuleValue: "capital letter",
      numberRuleValue: "number",
      specialCaseRuleValue: "special character",
    },
    searchableSelect: {
      addOption: "Use as new option",
    },
    contractRequests: {
      statusWAITING: "Waiting",
      statusCONFIRMED: "Confirmed",
      statusREJECTED: "Rejected",
      statusESCALATED: "Escalated",
      statusEXPIRED: "Expired",
      valid: "Valid",
    },
    validationSummary: {
      errors: "Error summary",
    },
    personalData: {
      documentNumberValidation: {
        format8digits: "The document number must have exactly 8 characters",
        format9digits: "The document number must have exactly 9 characters",
        format8Or9digits: "The document number must have 8 or 9 characters",
        format2letters7digits: "The document number must have exactly 9 characters, the first two may be letters",
      },
    },
    incomeList: {
      type: "Type",
      source: "The source",
      name: "Name",
      dateFrom: "Data",
      dateUntil: "This data",
      incomeFromSource: "Income from the source",
      byManager: "Filled by the manager",
      addEntry: "Add new",
      calculated: "Calculated",
      provided: "Submitted",
      valuable: "Evaluated",
      skipDate: "Skip",
    }
  },
};

export default translations;
