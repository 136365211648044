import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import notifications from "./modules/notifications";
import newApplication from "./modules/newApplication";
import sont from "./modules/sont";
import sellers from "./modules/sellers";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    notifications,
    sont,
    sellers,
    newApplication,
  },
});
