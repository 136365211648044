<template>
  <b-modal
    v-model="showModal"
    :title="$t('accountSettings.verifyContacts') | capitalize"
    hide-footer
    size="md"
    centered
    content-class="rounded-0"
    @hidden="closeAndReset()"
    header-class="border-bottom-0"
  >
    <b-alert :show="tryLater" variant="warning">
      {{ $t("accountSettings.codeWasSent") }}
    </b-alert>
    <b-form>
      <div class="d-flex flex-column">
        <count-down
          class="mb-3"
          :seconds="waitTime"
          @action="resendCode"
          ref="countDownTimer"
          :tooltipText="$t('login.deviceConfirmTimeout')"
          :sendAgainText="$t('login.deviceConfirmRepeat')"
          no-animation
          no-icon
        />
        {{
          (userInformation.authenticationMethod == SellerAuthentication.EMAIL
            ? $t("accountSettings.verifyEmail")
            : $t("accountSettings.verifySms")) | capitalize
        }}
      </div>
      <div class="d-flex mt-1">
        <b-form-group class="w-100">
          <b-input
            class="border-gray-1"
            type="number"
            inputmode="decimal"
            max="4"
            v-model="form.verificationCode"
            name="verificationCode"
            :data-vv-as="$t('login.smsCode')"
            :state="validateState('verificationCode')"
            ref="verificationCode"
            v-validate.immediate="{
              required: true,
              length: 4,
              is: confirmedCode,
            }"
            @keyup.enter="doVerification()"
            :formatter="fourDigitOnly"
            autofocus
            autocomplete="off"
            title=""
            @input="syncConfirmationValue()"
          ></b-input>
          <b-form-invalid-feedback id="verificationCode-feedback">
            {{ veeErrors.first("verificationCode") | capitalize }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="ml-2">
          <b-button variant="primary" @click="doVerification()" :disabled="veeErrors.all().length > 0" class="text-white rounded-more">
            {{ $t("accountSettings.enter") | capitalize }}
          </b-button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import validation from "@/mixins/validation";
import login from "@/mixins/login";
import soapRequests from "@/mixins/soapRequests";
import formatters from "@/mixins/formatters";
import userVerifyRequests from "@/mixins/userVerifyRequests";
import featureDetection from "@/mixins/featureDetection";
import releaseVersion from "@/mixins/releaseVersion";
import { SellerAuthentication } from "@/interfaces/seller";
import CountDown from "@/components/countDown.vue";
import { SontSendingRequestStatuses } from "@/interfaces/sont";
import { PasswordRemindRequestCodes } from "@/lib/userVerify/userVerifyParams";
import { GlobalStorageKeys } from "@/interfaces/applicationSettings";

export default mixins(validation, login, soapRequests, formatters, userVerifyRequests, featureDetection, releaseVersion).extend({
  name: "VerificationModal",
  components: {
    CountDown,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userInformation: {
      type: Object,
    },
    accountUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      sendingError: false,
      verificationModal: false,
      form: {
        verificationCode: "",
      },
      token: "",
      isLoading: false,
      SellerAuthentication,
      requestID: "",
      triedTimes: 0,
      tryLater: false,
      tryLaterTimeout: null as any,
      confirmedCode: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!this.value) return;

      this.sendingError = false;
      this.form.verificationCode = "";
      this.resendCode();
    },
    closeAndReset() {
      this.$emit("closed");

      this.sendingError = false;
      this.triedTimes = 0;
      this.form.verificationCode = "";
      this.isLoading = false;
      this.tryLater = false;
      clearTimeout(this.tryLaterTimeout);
      this.waitTime = 180;
    },
    resendCode() {
      this.sendingError = false;
      this.triedTimes = 0;

      if (this.accountUpdate) {
        this.sendUpdateAccountCode();
      } else {
        this.sendVerificationCode();
      }
    },
    sendUpdateAccountCode() {
      // this should be called when user must update contact details after login
      this.requestAccountUpdateCode(
        this.userInformation.userName,
        this.userInformation.authenticationMethod,
        this.userInformation.email,
        this.oldDashedPhoneFromValue(this.userInformation.phone) || this.userInformation.phone
      )
        .then((res: any) => {
          this.handleVerificationResponse(res);
          this.isLoading = false;
        })
        .catch(() => {
          // failed to identify user
          const errorText = this.userInformation.authenticationMethod == 1 ? "sendingErrorEmail" : "accountSettings.sendingErrorSms";
          this.showError(this.$t(errorText));
          this.$emit("sending-result", false);
        });
    },
    sendVerificationCode() {
      this.requestContactVerification(
        this.userInformation.userName,
        this.userInformation.authenticationMethod,
        this.userInformation.email,
        this.oldDashedPhoneFromValue(this.userInformation.phone) || this.userInformation.phone
      )
        .then((res: any) => {
          this.handleVerificationResponse(res);
          this.isLoading = false;
        })
        .catch(() => {
          // failed to identify user
          const errorText = this.userInformation.authenticationMethod == 1 ? "sendingErrorEmail" : "accountSettings.sendingErrorSms";
          this.showError(this.$t(errorText));
          this.$emit("sending-result", false);
        });
    },
    handleVerificationResponse(res: any) {
      if (res.info) {
        switch (res.info.code) {
          case PasswordRemindRequestCodes.PENDING:
          case PasswordRemindRequestCodes.SUCCESS:
            this.setFromResponse(res);
            this.wasNotificationSent(this.requestID);
            this.showModal = true;
            this.waitTime = this.getCodeValidationTimeout(+(res.info.wait || 0));
            break;
          case PasswordRemindRequestCodes.TRY_LATER:
            this.showModal = true;
            this.token = res.token;
            this.waitTime = this.getCodeValidationTimeout(+(res.info.wait || 0));

            this.tryLater = true;
            this.tryLaterTimeout = setTimeout(() => {
              this.tryLater = false;
            }, res.info.wait);
            break;
        }
      }
    },
    setFromResponse(res: any) {
      this.token = res?.token;
      this.requestID = res?.requestID;
    },
    wasNotificationSent(requestID: string) {
      setTimeout(() => {
        this.requestSendingStatus(requestID)
          .then((res: any) => this.processResponse(res, requestID))
          .catch((err) => this.processResponse(err, requestID))
          .finally(() => (this.triedTimes += 1));
      }, this.sontRequestTimeOut);
    },
    processResponse(res: any, requestID: string) {
      if (res?.status == SontSendingRequestStatuses.FAILED || this.triedTimes == this.sontRetryTimes) {
        this.$emit("sending-result", true);
        this.showModal = false;
        return;
      }

      if (res?.status == SontSendingRequestStatuses.SUCCESS) {
        this.showModal = true;
      }

      if (res?.status == SontSendingRequestStatuses.PENDING) {
        this.wasNotificationSent(requestID);
      }
    },
    syncConfirmationValue() {
      this.confirmedCode = this.form.verificationCode;
    },
    doVerification() {
      if (this.accountUpdate) {
        this.accountUpdateVerifyCode(this.token, this.form.verificationCode, this.userInformation.authenticationMethod)
          .then((res) => {
            if (res?.status) {
              this.$emit("verified", this.token);
              this.form.verificationCode = "";

              if (res.verified && res.verified.length) {
                this.$ls.set(
                  `${GlobalStorageKeys.DEVICE_VERIFICATION_COOKIE}-${this.user().userName}`,
                  { verified: true, deviceId: res.verified },
                  { ttl: 90 * 24 * 60 * 60 }
                );
                this.set2FaVerification(true);
              }
            } else {
              this.confirmedCode = "";
            }
          })
          .catch((error) => {
            console.log("rejected", error);
          });

        return;
      }

      // does the same but sends request to different endpoint
      this.verifyContactsCode(this.token, this.form.verificationCode)
        .then((res) => {
          if (res?.status) {
            this.$emit("verified", this.token);
            this.form.verificationCode = "";
            if (res.info?.wait) {
              this.waitTime = this.getCodeValidationTimeout(+(res.info.wait || 0));
            }
          } else {
            this.confirmedCode = "";
          }
        })
        .catch((error) => {
          console.log("rejected", error);
        });
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.init();
      } else {
        this.showModal = false;
      }
    },
  },
});
</script>
