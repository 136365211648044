import mixins from "vue-typed-mixins";
import { mapActions, mapGetters } from "vuex";
import { md5 } from "@/lib/md5Generator";
import { soapActions, SoapBodyParams, SoapErrors, SoapOK } from "@/lib/soap/soapParams";
import SoapClient from "@/lib/soap/soapClient";
import { NETWORK_ERROR_CODE } from "@/interfaces/applicationSettings";
import { SellerInterface } from "@/interfaces/seller";
import userVerifyRequests from "./userVerifyRequests";

const login = mixins(userVerifyRequests).extend({
  data() {
    return {
      loginError: false,
      loginErrorMessage: "",
      loginErrorCode: 0,
      networkErrorCode: NETWORK_ERROR_CODE,
      sontRetryTimes: 40, // how many times we will retry some status checks
      sontRequestTimeOut: 2000, // ms
      sontStatusRequestInterval: 500, // ms
      leftAttempts: 0,
      mustVerifyDevice: false,
      waitTime: 180,
      retry: false,
    };
  },
  computed: {
    ...mapGetters({
      is2FaVerificationPending: "auth/is2FaVerificationPending",
      is2FaEnabled: "auth/is2FaEnabled",
    }),
  },
  mounted() {
    this.sontRetryTimes = +(process.env.VUE_APP_SONT_RETRY_TIMES || 40);
    this.sontRequestTimeOut = +(process.env.VUE_APP_SONT_REQUEST_TIME_OUT || 2000);
    this.sontStatusRequestInterval = +(process.env.VUE_APP_SONT_STATUS_REQUEST_INTERVAL || 500);
  },
  methods: {
    ...mapActions({
      setUserLoggedIn: "auth/setUserLoggedIn",
      dropAll: "notifications/dropAll",
      extendSession: "auth/extendSession",
      setAdditionalInfo: "auth/setAdditionalInfo",
      set2FaVerification: "auth/set2FaVerification",
      set2FaVerificationToken: "auth/set2FaVerificationToken",
      notifyAboutAccountUpdate: "auth/notifyAboutUpdatedAccount",
      set2FaVerificationPending: "auth/set2FaVerificationPending",
      saveUserInBrowser: "auth/saveUserInBrowser",
    }),
    errorCodeValid(statusCode: string): boolean {
      return statusCode == SoapErrors.PASSWORD_TOO_OLD || statusCode == SoapOK;
    },
    async getPublicIP() {
      return await fetch(process.env.VUE_APP_FETCH_CLIENTS_PUBLIC_IP_FROM || "")
        .then((response) => response.text())
        .then((response) => {
          return response.trim();
        })
        .catch((error) => {
          return error;
        });
    },
    async login(userName: string, userPassword: string, usePlainPassword = false, isLimitedLogin = false) {
      this.loginError = false;
      this.leftAttempts = 0;
      const ip = await this.getPublicIP();
      const params: SoapBodyParams = {
        [soapActions.Prisijungti3]: {
          "ns1:vartotojas": userName,
          "ns1:slaptazodis": usePlainPassword ? userPassword : md5(userPassword),
          "ns1:ip": ip,
        },
      };

      await new SoapClient()
        .sendRequest(soapActions.Prisijungti3, params)
        .then((response) => {
          if (this.errorCodeValid(response.KlaidosKodas) && !response.Sesija.length) {
            this.loginError = true;
            this.loginErrorCode = response.KlaidosKodas;

            return;
          }

          this.loginError = false;
          this.loginErrorMessage = "";
          this.loginErrorCode = 0;

          response.userName = userName;
          response.isLimitedLogin = isLimitedLogin;

          this.setUserLoggedIn({ user: response });
          this.extendSession();
          this.dropAll();
          this.storeAdditionalInfo(response);

          if (this.is2FaEnabled) {
            this.mustVerifyDevice = true;
          } else {
            this.saveUserInBrowser();
          }
        })
        .catch((error) => {
          if (this.errorCodeValid(error.KlaidosKodas) && error.Sesija && error.Sesija.length && error.Tokenas && error.Tokenas.length) {
            this.loginError = false;
            this.loginErrorMessage = "";
            this.loginErrorCode = 0;

            const response = error;
            response.userName = userName;
            response.isLimitedLogin = isLimitedLogin;

            this.setUserLoggedIn({ user: response });
            this.extendSession();
            this.storeAdditionalInfo(response);

            if (this.is2FaEnabled) {
              this.mustVerifyDevice = true;
            } else {
              this.saveUserInBrowser();
            }
          } else {
            this.loginError = true;
            this.loginErrorCode = error.KlaidosKodas;
            this.loginErrorMessage = error.KlaidosPranesimas;
            if (error.KlaidosKodas == 1000) {
              if (error.LikeBandymai != 0) {
                this.loginErrorMessage = this.$t("soap.left") + error.LikeBandymai + this.$t("login.attempts");
                this.leftAttempts = error.LikeBandymai;
              } else {
                this.loginErrorCode = 1001;
              }
            }
          }
        });
    },
    storeAdditionalInfo(user: any) {
      const additionalUserInfo: SellerInterface | null = user?.PardavejoVartotojas || null;
      this.setAdditionalInfo(additionalUserInfo);
    },
    getCodeValidationTimeout(sontProxyReturnedWaitTimeMS: number): number {
      const realWaitTime = Math.round(+sontProxyReturnedWaitTimeMS / 1000);

      if (+realWaitTime <= 0) {
        return 0;
      }

      return realWaitTime;
    },
  },
});

export default login;
