import TimeClient from "@/lib/time/timeClient";
import Vue from "vue";
import { timeActions } from "@/lib/time/timeParams";

const TimeRequests = Vue.extend({
  methods: {
    timeNow() {
      return new Promise<any>((resolve, reject) => {
        new TimeClient()
          .sendRequest(timeActions.timeNow, "GET", null)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => reject(error));
      });
    },
  },
});

export default TimeRequests;
