import { SellerInterface } from "@/interfaces/seller";
import { Module, ActionContext } from "vuex";

// state
export const state = {
  items: null,
};

// getters
export const getters = {
  items: (state: { items: unknown }) => state.items,
};

// mutations
export const mutations = {
  setItems(state: { items?: SellerInterface[] }, items: SellerInterface[]) {
    state.items = items;
  },
};

// actions
export const actions = {
  resetStore({ commit }: ActionContext<typeof state, any>) {
    commit("setItems", null);
  },
  storeItems({ commit }: ActionContext<typeof state, any>, payload?: SellerInterface[]) {
    commit("setItems", payload);
  },
};

const sellersModule: Module<any, any> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};

export default sellersModule;
