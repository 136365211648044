<template>
  <b-input-group-append>
    <b-input-group-text @click="togglePasswordView()" :class="cssClasses">
      <b-icon :icon="passwordType == 'password' ? 'eye' : 'eye-slash'" />
    </b-input-group-text>
  </b-input-group-append>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "PasswordToggler",
  props: {
    value: {
      type: String,
      default: "password",
    },
    cssClasses: {
      type: String,
      default: "rounded border-2 border-gray-1 border-left-0 top-left-no-round bottom-left-no-round cursor-pointer bg-white",
    },
  },
  data() {
    return {
      passwordType: "password",
    };
  },
  methods: {
    togglePasswordView(): void {
      this.passwordType == "password" ? (this.passwordType = "text") : (this.passwordType = "password");

      this.$emit("input", this.passwordType);
    },
  },
  watch: {
    value(val: string): void {
      if (val) {
        this.passwordType = val;
      }
    },
  },
});
</script>
