var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('verification-modal',{attrs:{"user-information":_vm.form,"account-update":""},on:{"closed":_vm.onCloseVerificationModal,"verified":function($event){return _vm.onSuccessfulVerification($event)},"sending-result":_vm.verificationSendingResult},model:{value:(_vm.showVerificationCodeModal),callback:function ($$v) {_vm.showVerificationCodeModal=$$v},expression:"showVerificationCodeModal"}}),(_vm.errorMessage !== '')?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.errorMessage))+" ")]):_vm._e(),_vm._t("default"),(_vm.userData)?_c('b-form-group',{attrs:{"label":_vm._f("capitalize")(_vm.$t('accountSettings.authenticationMethod')),"label-for":"authenticationMethod"}},[_c('searchable-select',{directives:[{name:"validate",rawName:"v-validate",value:({
        excluded: _vm.AuthenticationMethods.NONE,
      }),expression:"{\n        excluded: AuthenticationMethods.NONE,\n      }"}],staticClass:"border-gray-1",attrs:{"id":"authenticationMethod","name":"authenticationMethod","options":_vm.authenticationOptions,"state":_vm.validateState('authenticationMethod'),"aria-describedby":"authentication-method-feedback","data-vv-as":_vm._f("capitalize")(_vm.$t('accountSettings.authenticationMethod')),"text-field":"text","value-field":"value","no-filter":"","no-filter-input":"","no-blank-option":""},model:{value:(_vm.form.authenticationMethod),callback:function ($$v) {_vm.$set(_vm.form, "authenticationMethod", $$v)},expression:"form.authenticationMethod"}}),_c('b-form-invalid-feedback',{attrs:{"id":`authentication-method-feedback`}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("authenticationMethod"))+" ")])],1):_vm._e(),_c('b-form-group',{attrs:{"label":_vm._f("capitalize")(_vm.$t('accountSettings.email')),"label-for":"emailMapper"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate.immediate",value:({
        required: _vm.form.authenticationMethod == _vm.AuthenticationMethods.EMAIL,
        email: true,
        emailFirstPart: true,
      }),expression:"{\n        required: form.authenticationMethod == AuthenticationMethods.EMAIL,\n        email: true,\n        emailFirstPart: true,\n      }",modifiers:{"immediate":true}}],staticClass:"border-gray-1 no-bg-image",attrs:{"id":"emailMapper","name":"email","type":"email","state":_vm.validateState('email'),"aria-describedby":"email-feedback","data-vv-as":_vm.$t('accountSettings.email'),"title":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-form-invalid-feedback',{attrs:{"id":`email-feedback`}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("email"))+" ")])],1),(_vm.userData)?_c('b-form-group',{attrs:{"label":_vm._f("capitalize")(_vm.$t('accountSettings.phone')),"label-for":"phoneMapper","label-class":"color-gray-5"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"input-group-text bg-white text-muted"},[_vm._v("+370")])]},proxy:true}],null,false,635383190)},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate.immediate",value:({
          required: _vm.form.authenticationMethod == _vm.AuthenticationMethods.SMS,
          regex: _vm.constantPhoneRegex,
        }),expression:"{\n          required: form.authenticationMethod == AuthenticationMethods.SMS,\n          regex: constantPhoneRegex,\n        }",modifiers:{"immediate":true}}],staticClass:"border-gray-1 no-bg-image",attrs:{"id":"phoneMapper","name":"phone","type":"text","formatter":_vm.dashedPhoneFromValue,"placeholder":"6XXXXXXX","state":_vm.validateState('phone'),"aria-describedby":"phone-feedback","data-vv-as":_vm._f("capitalize")(_vm.$t('accountSettings.phone')),"inputmode":"decimal","autocomplete":"new-password"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('b-form-invalid-feedback',{attrs:{"id":`phone-feedback`}},[_vm._v(_vm._s(_vm.veeErrors.first("phone")))])],1)],1):_vm._e(),_c('b-form-group',{attrs:{"label":_vm._f("capitalize")(_vm.$t('accountSettings.newPassword')),"label-for":"newPassword"}},[_c('b-form-input',{staticClass:"d-no-ne h-0",attrs:{"name":"username","autocomplete":"username"},model:{value:(_vm.form.userName),callback:function ($$v) {_vm.$set(_vm.form, "userName", $$v)},expression:"form.userName"}}),_c('b-input-group',[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate.immediate",value:({
          required: true,
        }),expression:"{\n          required: true,\n        }",modifiers:{"immediate":true}}],ref:"password",staticClass:"border-gray-1",class:{ 'no-bg-image': !_vm.form.password.length && !_vm.form.passwordConfirmation.length },attrs:{"autocomplete":"new-password","id":"newPassword","type":_vm.passwordType1,"name":"password","data-vv-as":_vm._f("capitalize")(_vm.$t('accountSettings.newPassword')),"state":_vm.passwordValid},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('password-toggler',{model:{value:(_vm.passwordType1),callback:function ($$v) {_vm.passwordType1=$$v},expression:"passwordType1"}}),_c('b-form-invalid-feedback',{attrs:{"id":"newPassword-feedback"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.passwordErrors(_vm.form.password))}})])],1)],1),_c('b-form-group',{attrs:{"label":_vm._f("capitalize")(_vm.$t('accountSettings.passwordConfirmation')),"label-for":"passwordConfirmation"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate.immediate",value:({
          required: true,
          confirmed: _vm.form.password,
        }),expression:"{\n          required: true,\n          confirmed: form.password,\n        }",modifiers:{"immediate":true}}],staticClass:"border-gray-1",class:{ 'no-bg-image': !_vm.form.password.length && !_vm.form.passwordConfirmation.length },attrs:{"autocomplete":"new-password","id":"passwordConfirmation","type":_vm.passwordType2,"name":"passwordConfirmation","data-vv-as":_vm._f("capitalize")(_vm.$t('accountSettings.passwordConfirmation')),"state":_vm.validateState('passwordConfirmation')},model:{value:(_vm.form.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirmation", $$v)},expression:"form.passwordConfirmation"}}),_c('password-toggler',{model:{value:(_vm.passwordType2),callback:function ($$v) {_vm.passwordType2=$$v},expression:"passwordType2"}}),_c('b-form-invalid-feedback',{attrs:{"id":"passwordConfirmation-feedback"}},[_vm._v(" "+_vm._s(_vm.veeErrors.first("passwordConfirmation"))+" ")])],1)],1),_c('b-button',{staticClass:"w-100 text-white btn-sm p-3 rounded-more",attrs:{"variant":"primary","disabled":_vm.isSaveButtonDisabled},on:{"click":function($event){$event.preventDefault();return _vm.submitAccountForm.apply(null, arguments)}}},[(_vm.saving)?_c('b-spinner',{staticClass:"my-auto mr-2",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t("login.saveData")))+" ")],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }