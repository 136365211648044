import Vue from "vue";
import translations from "@/translations";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "lt",
  messages: translations,
});

export default i18n;
