import { AuthenticationMethods } from "@/interfaces/applicationSettings";
import { SellerInterface } from "@/interfaces/seller";
import { routerPaths } from "@/router/routerPaths";
import Vue from "vue";

const accountUpdate = Vue.extend({
  data() {
    return {
      routerPaths,
    };
  },
  methods: {
    phoneVerified(seller: SellerInterface, phone: string, selectedAuthMethod: number) {
      if (selectedAuthMethod == AuthenticationMethods.SMS) {
        return 1;
      }

      if ((seller.MobilusTelefonas || "") != phone && phone != "") {
        return 0;
      }

      return seller.MobilusTelefonas == phone ? seller.TelefonasPatvirtintas : 0;
    },
    emailVerified(seller: SellerInterface, email: string, selectedAuthMethod: number) {
      if (selectedAuthMethod == AuthenticationMethods.EMAIL) {
        return 1;
      }

      if ((seller.Emailas || "") != email && email != "") {
        return 0;
      }

      return seller.Emailas == email ? seller.EmailasPatvirtintas : 0;
    },
    getVerifiedFieldsValues(seller: SellerInterface, authenticationMethod: AuthenticationMethods, phone: string, email: string) {
      return {
        TelefonasPatvirtintas: this.phoneVerified(seller, phone, authenticationMethod),
        EmailasPatvirtintas: this.emailVerified(seller, email, authenticationMethod),
      };
    },
  },
});

export default accountUpdate;
