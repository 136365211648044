const capitalize = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

export const validationMessagesLt: any = {
  custom: {
    amount: {
      required: () => " ",
      min_value: (field: string, min: number) => `${field} turi būti ${Number(min).toFixed(2)} arba daugiau`,
    },
    personalCode: {
      digits: (field: string, min: number) => `Laukelio ${field} reikšmė turi būti ${min} skaitmenų`,
      regex: (field: string) => `Laukelio ${field} reikšmė neteisinga`,
    },
    spousePersonalCode: {
      digits: (field: string, min: number) => `Laukelio ${field} reikšmė turi būti ${min} skaitmenų`,
      regex: (field: string) => `Laukelio ${field} reikšmė neteisinga`,
    },
    authenticationMethod: {
      excluded: (field: string) => `Laukelis ${field} privalomas`,
    },
    passwordConfirmation: {
      confirmed: () => "Slaptažodžiai nesutampa",
    },
    documentType: {
      included: (field: string) => `Laukelis ${field} privalomas`,
    },
    spouse_documentType: {
      included: (field: string) => `Laukelis ${field} privalomas`,
    },
    dateFrom: {
      date_format: (field: string, [format]: string) => `Tinkamas formatas: ${format}`,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      date_between: (field: string, [min, max]: any) => `Data turi būti ankstesnė nei ${max}`,
    },
    dateTo: {
      date_format: (field: string, [format]: string) => `Tinkamas formatas: ${format}`,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      date_between: (field: string, [min, max]: any) => `Data turi būti vėlesne nei ${min}`,
    },
    moneyUsage: {
      excluded: (field: string) => `Laukelis ${field} privalomas`,
    },
  },
  messages: {
    _default: (field: string) => `${capitalize(field)} reikšmė netinkama`,
    // after: (field: string, [target]: string) => `Laukelyje ${field} turi būti po ${target}`,
    after: () => "Neteisinga data",
    alpha: (field: string) => `Laukelyje ${field} leidžiamos tik raidės`,
    alpha_dash: (field: string) => `Laukelyje ${field} leidžiamos tik raidės, skaičiai bei brūkšneliai`,
    alpha_num: (field: string) => `Laukelyje ${field} leidžiamos tik raidės ir skaičiai`,
    alpha_spaces: (field: string) => `Laukelyje ${field} leidžiamos tik raidės ir tarpai`,
    // before: (field: string, [target]: string) => `${field} turi būti prieš ${target}`,
    before: () => "Neteisinga data",
    between: (field: string, [min, max]: any) => `Laukelio ${field} reikšmė turi būti tarp ${min} ir ${max}`,
    confirmed: (field: string) => `Laukelio ${field} patvirtinimas nesutampa`,
    credit_card: (field: string) => `Laukelis ${field} neteisingas`,
    date_between: (field: string, [min, max]: any) => `Laukelio ${field} reikšmė turi būti tarp ${min} ir ${max}`,
    // date_format: (field: string, [format]: string) => `Laukelio ${field} formatas privalo būti toks - ${format}`,
    date_format: () => "Neįvesta data",
    decimal: (field: string, [decimals = "*"] = []) =>
      `Laukelis ${field} turi būti skaitmuo su${!decimals || decimals === "*" ? "" : " " + decimals} skaičium(-ias) po kablelio`,
    digits: (field: string, [length]: any) =>
      `Laukelis ${field} turi turėti lygiai ${length} simbol${length == 1 ? "į" : length < 10 ? "ius" : "ių"}`,
    dimensions: (field: string, [width, height]: any) => `${capitalize(field)} turi būti ${width} px x ${height} px`,
    email: (field: string) => `Laukelis ${field} turi būti teisingo formato`,
    excluded: (field: string) => `${capitalize(field)} reikšmė nėra leidžiama`,
    ext: (field: string) => `${capitalize(field)} turi būti tinkamas failas`,
    image: (field: string) => `${capitalize(field)} turi būti paveikslėlis`,
    included: (field: string) => `${capitalize(field)} reikšmė nėra leidžiama`,
    ip: (field: string) => `${capitalize(field)} turi būti ip adresas`,
    max: (field: string, [length]: any) => `${capitalize(field)} negali būti ilgesnis nei ${length}`,
    max_value: (field: string, [max]: any) => `${capitalize(field)} turi būti ${max} arba mažiau`,
    mimes: (field: string) => `${capitalize(field)} privalo turėti tinkmą failo tipą`,
    min: (field: string, [length]: any) => `${capitalize(field)} ilgis privalo būti bent ${length}`,
    min_value: (field: string, [min]: any) => `${capitalize(field)} turi būti ${min} arba daugiau`,
    numeric: (field: string) => `${capitalize(field)} turi būti tik skaitmenys`,
    regex: (field: string) => `Laukelio ${field} formatas netinkamas`,
    required: (field: string) => `Laukelis ${field} privalomas`,
    size: (field: string, [size]: any) => `${capitalize(field)} turi būti mažesnis nei ${size}`,
    url: (field: string) => `${field} turi būti internetinis adresas`,
    inList: (field: string) => `Laukelis ${field} privalomas`,
    isAccepted: () => "Įvestos neteisingos kontrolinės eilutės reikšmės",
  },
};
