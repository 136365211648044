import CryptoJS from "crypto-js";

export const LocalStorageSlimSetup = {
  secret: process.env.VUE_APP_LS_CRYPT_KEY,
  encrypt: (process.env.VUE_APP_LS_CRYPT_KEY?.length || 0) > 0,
  encrypter: (data: any, secret: any) => CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString(),
  decrypter: (data: any, secret: any) => {
    try {
      return JSON.parse(CryptoJS.AES.decrypt(data, secret).toString(CryptoJS.enc.Utf8));
    } catch (e) {
      // incorrect secret, return the encrypted data instead / or null
      return data; // or return null;
    }
  },
};
